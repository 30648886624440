//LoginAdmin.js
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';

const LoginAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { setAdmin } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`https://jetraduis-server.vercel.app/api/admin/signin`, {
        username,
        password,
      });

      const { token, admin } = response.data;

      localStorage.setItem('adminToken', token);
      localStorage.setItem('adminId', admin._id);

      // Update the AuthContext with the user data
      setAdmin(admin);

      navigate(`/ admin / dashboard / ${admin._id}`);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <h1>Admin Sign In</h1>
      <form onSubmit={handleLogin}>
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={handleUsernameChange} required />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            autoComplete="current-password"
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>
        Don't have an admin account? <Link to="/admin/signup">Sign up</Link>
      </p>
    </div>
  );
};

export default LoginAdmin;
