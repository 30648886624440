//SignupUser.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';

const SignupUser = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://jetraduis-server.vercel.app/api/users/signup`, {
        fullName,
        email,
        password,
      });
      const { token, user } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('userId', user._id);
      setUser(user);
      setRegistrationSuccess(true);

      navigate(`/ userDashboard / ${user._id}`);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='loginUser'>
      {registrationSuccess ? (
        <div>
          <h2>{t('Inscription réussie !')}</h2>
        </div>
      ) : (
        <div>
          <h2>{t('Inscription')}</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder={t('Nom complet')}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <input
              type="email"
              placeholder={t('Email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder={t('Mot de passe')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='buttonSubmit'>
              <button className='submit' type="submit">{t('inscription')}</button>
            </div>

          </form>
        </div>
      )}
    </div>
  );
};

export default SignupUser;
