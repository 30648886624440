//Contact.js 
import React, { useState, useContext } from 'react';
import { FaFacebook, FaXTwitter, FaInstagram } from 'react-icons/fa6';
import './css/Contact.css'
import { LanguageContext } from '../contexts/LanguageContext';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className='contact-container' role="region" aria-labelledby="contactHeading">
      <h2 id="contactHeading">{t('Contactez-nous')}</h2>
      <p>{t('Vous avez une question ou souhaitez nous contacter ? Envoyez-nous un message en utilisant le formulaire ci-dessous')}</p>
      <form onSubmit={handleSubmit} aria-labelledby="formHeading">
        <div>
          <label htmlFor="name">{t('Nom')} :</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="email">{t('E-mail')} :</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="message">{t('Message')} :</label>
          <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} required />
        </div>
        <button type="submit" aria-label={t('Envoyer')} aria-labelledby="formHeading">{t('Envoyer')}</button>
      </form>
      <div role="group" aria-labelledby="socialHeading">
        <h3 id="socialHeading">{t('Restez connecté')}</h3>
        <p>{t('Suivez-nous sur les réseaux sociaux pour des mises à jour et plus encore')}</p>
        <div className='social-links'>
          <a href="#" target="_blank" rel="noopener noreferrer" aria-label={t('Facebook')}>
            <FaFacebook />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" aria-label={t('Twitter')}>
            <FaXTwitter />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" aria-label={t('Instagram')}>
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;