// ResetPassword.jsx
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams();
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;

    const resetPassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Les mots de passe ne correspondent pas.');
            return;
        }

        try {
            const token = searchParams.get('token');
            await axios.post(`https://jetraduis-server.vercel.app/api/users/reset-password-user`, {
                token,
                newPassword
            });
            setMessage('Votre mot de passe a été réinitialisé avec succès.');
        } catch (error) {
            setMessage('Erreur lors de la réinitialisation du mot de passe.');
        }
    };

    return (
        <div>
            <h2>{t('Réinitialisation du mot de passe')}</h2>
            <form onSubmit={resetPassword}>
                <div>
                    <label>{t('Nouveau mot de passe')} :</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>{t('Confirmer le nouveau mot de passe')} :</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">{t('Réinitialiser le mot de passe')}</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ResetPassword;
