// LoaderContext.js
import React, { createContext, useState } from 'react';
export const LoaderContext = createContext({
    loading: false,
    setLoading: () => { },
});

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    return (
        <LoaderContext.Provider value={{ loading, setLoading }} aria-live="polite" aria-busy={loading}>
            {children}
        </LoaderContext.Provider>
    );
};
