// Cart.js
import React, { useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Elements } from "@stripe/react-stripe-js";
import Modal from 'react-modal';
import { AuthContext } from '../contexts/AuthContext';
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from './CheckoutForm';
import emptyCart from '../madia/images/emptyCart.png';
import './css/Cart.css';
import { LanguageContext } from '../contexts/LanguageContext';

const stripePromise = loadStripe("pk_live_51OKQmkGIYeLUOpCP6J3zaQHAilzCfRpUttOgX7UUsH8Pt7bguJ3rnlYEY4Iab7SuRcuqFc0q33AatZrPjyfNq4uR00P7TlZvsy");

const Cart = () => {
    const { user } = useContext(AuthContext);
    const [userOrders, setUserOrders] = useState([]);
    const [currentOrderId, setCurrentOrderId] = useState(null);
    const [paymentStep, setPaymentStep] = useState(1);
    const [clientSecret, setClientSecret] = useState("");
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;
    const userId = user ? user._id : null;

    const [hasRefreshed, setHasRefreshed] = useState(false);


    const fetchUserOrders = useCallback(async () => {
        if (userId) {
            try {
                const response = await fetch(`https://jetraduis-server.vercel.app/api/users/${userId}/orders`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accept': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Error fetching user orders');
                }

                const data = await response.json();
                setUserOrders(data.orders);
                if (!hasRefreshed) {
                    setHasRefreshed(true); // Mettre à jour le state pour indiquer que le rafraîchissement a eu lieu
                }

            } catch (error) {
                console.error(error);
            }
        }
    }, [userId, hasRefreshed]);

    useEffect(() => {
        fetchUserOrders();
    }, [fetchUserOrders]);



    const confirmOrder = async (orderId) => {
        try {
            const response = await axios.post(
                `https://jetraduis-server.vercel.app/api/orders/${orderId}/confirm`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.status === 201) {
                setCurrentOrderId(orderId);
                setPaymentStep(2);
            }
        } catch (error) {
            console.error('Error confirming order:', error);
        }
    };

    const handleConfirmOrder = async (orderId) => {
        await confirmOrder(orderId);
    };

    useEffect(() => {
        if (paymentStep === 2) {
            const currentOrder = userOrders.find(order => order.status === 'cart');
            const orderId = localStorage.getItem('orderId');

            fetch(`https://jetraduis-server.vercel.app/api/stripe/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ order: currentOrder, orderId }),
            })
                .then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret));
        }
    }, [paymentStep, userOrders]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const handleRemoveItem = async (orderId) => {
        try {
            await axios.delete(
                `https://jetraduis-server.vercel.app / api / orders / ${orderId} / remove`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            fetchUserOrders();


        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    };

    return (
        <div className='cart'>
            {user && userOrders.length > 0 ? (
                <div>
                    <h2>{t('Confirmation de commande')}</h2>
                    {userOrders.map((order, index) => (
                        <div key={order._id}>
                            {order.status === 'cart' ? (
                                <div>
                                    <div>{t('Langue source')} : {t(order.sourceLanguage)}</div>
                                    <div>{t('Langue cible')} : {t(order.targetLanguage)}</div>
                                    <div>{t('Option de livraison')} : {t(order.deliveryOption)}</div>
                                    <div>{t('Nombre de pages')} : {order.numPages}</div>
                                    <div>{t('Montant total')} : {order.totalAmount} €</div>
                                    {paymentStep === 1 && (
                                        <div>
                                            <button
                                                className="list-group-item list-group-item-action active"
                                                onClick={() => handleConfirmOrder(order._id)}
                                            >
                                                {t('Confirmer la commande')}
                                            </button>
                                            <button onClick={() => setPaymentStep(2)}>{t('Suivant')}</button>
                                            <button onClick={() => {
                                                handleRemoveItem(order._id);
                                                fetchUserOrders();
                                            }}>
                                                {t('Supprimer l\'article')}
                                            </button>
                                        </div>
                                    )}
                                    {paymentStep === 2 && clientSecret && (
                                        <div>
                                            <Elements options={options} stripe={stripePromise}>
                                                <CheckoutForm orderId={order._id} />
                                            </Elements>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    ))}
                    {!userOrders.some(order => order.status === 'cart') && (
                        <div className='empty_cart'>
                            <p>{t('Aucune commande dans le panier')}{' '}</p>
                            <img src={emptyCart} alt="empty cart" />
                        </div>
                    )}
                </div>
            ) : (
                <div className='empty_cart'>
                    {userOrders.length === 0 && <p>{t('Aucune commande dans le panier')}{' '}</p>}
                    {userOrders.length > 0 && !userOrders.some(order => order.status === 'cart') && (
                        <div>
                            <p>{t('Aucune commande dans le panier')}{' '}</p>
                            <img src={emptyCart} alt="empty cart" />
                        </div>
                    )}
                </div>
            )}
            {currentOrderId && (
                <Modal>
                    {/* Your payment success/failure modal content */}
                    <div>
                        {t('Paiement réussi ! Numéro de commande')} : {currentOrderId}
                    </div>
                </Modal>
            )}
        </div>
    );
}
export default Cart;
