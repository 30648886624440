// LanguageProvider.js
import React, { createContext, useState, useEffect } from 'react';
import translationsEN from '../translations/en.json';
import translationsFR from '../translations/fr.json';
import translationsAR from '../translations/ar.json';
import translationsES from '../translations/es.json';
import axios from 'axios';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationsEN },
        fr: { translation: translationsFR },
        ar: { translation: translationsAR },
        es: { translation: translationsES },
    },
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
        escapeValue: false,
    },
});

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('fr');
    const [translations, setTranslations] = useState(translationsFR);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        i18n.changeLanguage(language);
        axios.defaults.headers.common['Accept-Language'] = language;
    }, [language]);

    useEffect(() => {
        const languageTranslations = {
            en: translationsEN,
            fr: translationsFR,
            ar: translationsAR,
            es: translationsES,
        };

        const timeoutId = setTimeout(() => {
            setTranslations(languageTranslations[language.toLowerCase()] || translationsEN);
            setLoading(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [language, translationsEN, translationsFR, translations]);

    axios.interceptors.request.use((config) => {
        config.headers['Accept-Language'] = language;
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const changeLanguage = (lang) => {
        setLoading(true);
        setLanguage(lang);
    };

    useEffect(() => {
        changeLanguage('fr');
    }, []);

    return (
        <LanguageContext.Provider value={{ language, translations, loading, changeLanguage, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
