//Footer.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './components.css';
import { LanguageContext } from '../contexts/LanguageContext';

const Footer = () => {
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;

  return (
    <footer className="footer" role="contentinfo">
      <div className="footer-content">
        <div className="footer-section">
          <h4>{t('Services')}</h4>
          <ul className="footer-links">
            <li><Link to="/translation" aria-label={t('Traduction')}>{t('Traduction')}</Link></li>
            <li><Link to="/localization" aria-label={t('Localisation')}>{t('Localisation')}</Link></li>
            <li><Link to="/interpretation" aria-label={t('Interprétation')}>{t('Interprétation')}</Link></li>
            <li><Link to="/proofreading" aria-label={t('Relecture')}>{t('Relecture')}</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>{t('Nous contacter')}</h4>
          <p>
            {t('Téléphone')} : <a href="tel:0123456789">01.23.45.67.89</a>
          </p>
          <p>
            {t('Email')} : <a href="mailto: abc@example.com">contact@agency.com</a>
          </p>
          <div className="social-icons" aria-label={t('Réseaux sociaux')}>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          </div>
        </div>

        <div className="footer-section">
          <h4>{t('Légal')}</h4>
          <div className='links'>
            <Link to="/terms" aria-label={t('Conditions d\'utilisation')}>{t('Conditions d\'utilisation')}</Link>
            <Link to="/privacy" aria-label={t('Politique de confidentialité')}>{t('Politique de confidentialité')}</Link>
          </div>
        </div>
        <div className="footer-contact-button">
          <button><Link to="/contact" aria-label={t('Nous contacter')}>{t('Nous contacter')}</Link></button>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} {t('Agence de Traduction. Tous droits réservés')}.</p>
      </div>
    </footer>
  );
};

export default Footer;
