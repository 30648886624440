import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';

const RefundConfirmation = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const refundId = queryParams.get('refundId');
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;

    return (
        <div>
            <h2>{t('Confirmation de remboursement')}</h2>
            {refundId ? (
                <div>{t('Votre remboursement avec l\'identifiant')} : <p>{refundId}</p> {t('a été traité avec succès. Merci')} !</div>
            ) : (
                <p>{t('Chargement')} ...</p>
            )}
            <p>
                <Link to="/user-dashboard">{t('Retour au tableau de bord de l\'utilisateur')}</Link>
            </p>
        </div>
    );
};

export default RefundConfirmation;
