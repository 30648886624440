// UserDashboard.js
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LanguageContext } from '../contexts/LanguageContext';
import './css/UserDashboard.css';

const UserDashboard = () => {

  const { user, logoutUser } = useContext(AuthContext);
  const { userId } = useParams();
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;
  const [userOrders, setUserOrders] = useState([]);
  const [commentFormVisible, setCommentFormVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [canCancel, setCanCancel] = useState(true);
  const [message, setMessage] = useState("");
  const [orderCommentsVisible, setOrderCommentsVisible] = useState({});
  const [tableVisible, setTableVisible] = useState({});


  const toggleCommentsVisibility = (orderId) => {
    setOrderCommentsVisible((prevVisibility) => ({
      ...prevVisibility,
      [orderId]: !prevVisibility[orderId],
    }));
  };

  const toggleTableVisibility = (orderId) => {
    setTableVisible((prevVisibility) => ({
      ...prevVisibility,
      [orderId]: !prevVisibility[orderId],
    }));
  };


  const submitComment = async (orderId) => {
    try {
      if (!orderId) {
        console.error('Order ID is not defined.');
        return;
      }
      const response = await fetch(`https://jetraduis-server.vercel.app/api/users/${userId}/orders/${orderId}/comment`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: userId, text: comment }),
      });
      if (!response.ok) {
        throw new Error(`Error submitting comment for order ${orderId}`);
      }
      setComment('');
      setCommentFormVisible(false);
      fetchUserOrders();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserOrders = useCallback(async () => {
    try {
      const response = await fetch(`https://jetraduis-server.vercel.app/api/users/${userId}/orders`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching user orders');
      }
      const data = await response.json();
      setUserOrders(data.orders);
    } catch (error) {
      console.error(error);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserOrders();
  }, [fetchUserOrders]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setFileUploaded(!!e.target.files[0]);
  };


  const handleFileUpload = async (orderId) => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile, selectedFile.name);
        const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/upload-file/${orderId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setSelectedFile(null);
      }
    } else {
      console.error('Aucun fichier sélectionné');
    }
  };

  const handleFileUploadComment = async (orderId) => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile, selectedFile.name);

        const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/upload-file/${orderId}/comment`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setSelectedFile(null);
      }
    } else {
      console.error('Aucun fichier sélectionné');
    }
  };

  const initiateRefund = async (orderId, res) => {
    const order = userOrders.find(order => order._id === orderId);

    if (!order) {
      console.error('Order not found.');
      return;
    }
    if (order.status === 'pending') {
      try {
        const refundReason = "Customer requested refund";
        const response = await axios.post(`https://jetraduis-server.vercel.app/api/stripe/refunds`, {
          chargeId: order._id,
          amount: order.totalAmount,
          reason: refundReason,
        });
      } catch (error) {
        console.error('Error initiating refund:', error);
      }
    } else {
      return res.status(400).json({ error: 'Refund cannot be initiated for orders with the current status' });

    }
  };

  const cancelOrder = async (orderId) => {
    try {
      const response = await axios.put(
        `https://jetraduis-server.vercel.app/api/users/orders/${orderId}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Order cancelled successfully.");
        fetchUserOrders();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error cancelling order:', error);
      alert("Error cancelling order.");
    }
  };
  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };
  if (!user || user._id !== userId) {
    return <p>{t('Vous n\'avez pas la permission d\'accéder à ce tableau de bord')}.</p>;
  }
  return (
    <div className='user-dashboard'>
      <h1>{t('Tableau de bord')}</h1>
      {user ? (
        <div>
          <div>
            {user.fullName && <p>{t('Nom complet')} : {user.fullName}</p>}
            {user.email && <p>{t('Email')} : {user.email}</p>}
            <h2>{t('Commandes')} :</h2>
          </div>
          <div className='table-container'>
            {userOrders.map((order, index) => (
              <div key={order._id}>
                <tr>
                  <th>{t('Numéro de commande')} : </th>
                  <td>{`Order ${index + 1}`}</td>
                </tr>
                <tr>
                  <th>{t('Date')}</th>
                  <td>{formatDateTime(order.createdAt)}</td>
                </tr>
                <button onClick={() => toggleTableVisibility(order._id)} className='dashboard-button'>
                  {tableVisible[order._id] ? t('Masquer la commande') : t('Afficher la commande')}
                </button>


                {tableVisible[order._id] && (
                  <div>
                    <table className='table-orders'>
                      <tr>
                        <th>{t('Date')}</th>
                        <td>{formatDateTime(order.createdAt)}</td>
                      </tr>
                      <tr>
                        <th>{t('Option de livraison')}</th>
                        <td>{t(order.deliveryOption)}</td>
                      </tr>
                      <tr>
                        <th>{t('Nombre de pages')}</th>
                        <td>{order.numPages}</td>
                      </tr>
                      <tr>
                        <th>{t('Montant total')}</th>
                        <td>{order.totalAmount}</td>
                      </tr>
                      <tr>
                        <th>{t('Etat')}</th>
                        <td>{t(order.status)}
                          {' '}
                          {order.status === 'cart' ? (
                            <button onClick={() => window.location.href = '/cart'}>
                              {t('Veuillez compléter votre commande ici.')}
                            </button>
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Annuler')}</th>
                        <td>
                          <button
                            onClick={() => cancelOrder(order._id) + initiateRefund(order._id)}
                            disabled={!canCancel}
                          >
                            {t('Annuler la commande')}
                          </button>
                          {!canCancel && <div>{message}</div>}
                          {order.status !== 'cancelled' && order.unableToCancel && (
                            <span style={{ color: 'red' }}> {t('La commande ne peut pas être annulée en raison de la limite de délai autorisée')}.</span>
                          )}
                        </td>
                      </tr>
                    </table>

                    {/* commentaireeeeeeeees */}
                    <div>
                      <div>
                        <button onClick={() => toggleCommentsVisibility(order._id)} className='dashboard-button-comment'>
                          {orderCommentsVisible[order._id] ? t('Masquer les commentaires') : t('Afficher les commentaires')}
                        </button>
                      </div>
                      <div>
                        {orderCommentsVisible[order._id] && (
                          <div className='comments'>
                            <div key={order._id} className="comment-container">
                              <div className='comment-unity'>
                                {order.comment?.filter((comment) => comment.approved)?.map((comment) => (
                                  <div key={comment._id}>
                                    {comment.user && (
                                      <div>
                                        <h3>{formatDateTime(comment.createdAt)}</h3>
                                        <p >
                                          <h4 className='user-name-comment'> {comment.fullName} :
                                          </h4>{comment.text}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <div>
                                <div className='add-comment'>
                                  <h3>{t('Ajouter un commentaire')}</h3>
                                  <textarea
                                    rows="4"
                                    cols="50"
                                    placeholder={t('Mettez votre commentaire')}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                  ></textarea>
                                  <button onClick={() => submitComment(order._id)} className='button-file-comment'>{t('Soumettre le commentaire')}</button>
                                </div>
                              </div>
                              <div>
                                {order.commentFile && order.commentFile.length > 0 ? (
                                  <div>
                                    <p>{t('Il y a un fichier commentaire')} :</p>
                                    <ul>
                                      {order.commentFile.map((file, index) => (
                                        <li key={index} className='file-comment'>
                                          {decodeURIComponent(file)}
                                          <a
                                            href={`https://jetraduis-server.vercel.app /api/users / ${userId} /comment-files?orderNumber=${order.orderNumber}&fileIndexes=${index}`}
                                            download={`comment-file-${userId}-${index}.zip`
                                            }
                                          >
                                            {t('Télécharger')}
                                          </a >
                                        </li >
                                      ))}
                                    </ul >
                                  </div >
                                ) : (
                                  <a
                                    href={`https://jetraduis-server.vercel.app/api/users/${userId}/comment-files?orderNumber=${order.orderNumber}`}
                                    download={`comment-files-${userId}.zip`}
                                  >
                                    {t('Télécharger le fichier')}
                                  </a>
                                )}
                              </div >
                              {fileUploaded && (
                                <a
                                  href={`https://jetraduis-server.vercel.app/api/users/${userId}/comment-files?orderNumber=${order.orderNumber}`}
                                  download={`comment-files-${userId}.zip`}
                                >
                                  {t('Télécharger le fichier')}
                                </a>
                              )}
                              <input
                                type="file"
                                onChange={handleFileChange}
                              />
                              <button onClick={() => handleFileUploadComment(order._id)}> {t('Envoyer')}</button>
                            </div >
                            <button onClick={() => toggleCommentsVisibility(order._id)}>
                              {orderCommentsVisible[order._id]}X
                            </button>
                          </div >
                        )}
                      </div >
                    </div >
                  </div >
                )}
              </div >
            ))}
          </div >
        </div >
      ) : (
        <p>{t('Veuillez vous connecter pour accéder au tableau de bord')}.</p>
      )
      }
    </div >
  );
};

export default UserDashboard;
