import React, { useState, useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import './components.css';
import { FaLanguage } from 'react-icons/fa';

const LanguageSwitcher = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleMenuItemClick = () => {
        setMenuOpen(false);
    };

    const handleLanguageChange = (lang) => {
        languageContext.changeLanguage(lang);
    };

    return (
        <div className={`language-switcher ${isMenuOpen ? 'menu-open' : ''}`} aria-label={t('Langue')}>
            <button className="language-switcher button" onClick={toggleMenu} aria-label="Toggle Menu">
                <FaLanguage className="language-logo" alt="" aria-label={t('Langue')} /><strong className='language-displayed'>{translations.language}</strong>
            </button>
            <div className="language-dropdown" role="menu">
                <button onClick={() => { handleLanguageChange('fr'); handleMenuItemClick(); }} aria-label="Français">
                    FR
                </button>
                <button onClick={() => { handleLanguageChange('en'); handleMenuItemClick(); }} aria-label="English">
                    EN
                </button>
                <button onClick={() => { handleLanguageChange('ar'); handleMenuItemClick(); }} aria-label="العربية">
                    AR
                </button>
                <button onClick={() => { handleLanguageChange('es'); handleMenuItemClick(); }} aria-label="Español">
                    ES
                </button>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
