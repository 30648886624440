// Discussion.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { LanguageContext } from '../../../contexts/LanguageContext';

import './Forum.css';

const Discussion = () => {
    const { id } = useParams();
    const { user } = useContext(AuthContext);
    const [discussion, setDiscussion] = useState({});
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sortBy, setSortBy] = useState('date');
    const [searchTerm, setSearchTerm] = useState('');
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;

    useEffect(() => {
        const fetchDiscussionDetails = async () => {
            try {
                const response = await fetch(`https://jetraduis-server.vercel.app/api/forum/discussions/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setDiscussion(data.discussion);
                    setMessages(data.messages);
                } else {
                    console.error('Failed to fetch discussion details. Server returned:', response.status);
                }
            } catch (error) {
                console.error('Error while fetching discussion details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDiscussionDetails();
    }, [id]);

    const sendMessage = async () => {
        try {
            setLoadingMessage(true);

            const response = await fetch(`https://jetraduis-server.vercel.app/api/forum/discussions/${id}/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content: newMessage, discussionId: id, author: user }),
            });

            if (response.ok) {
                const newMessageData = await response.json();

                setTimeout(async () => {
                    const updatedMessagesResponse = await fetch(`https://jetraduis-server.vercel.app/api/forum/discussions/${id}`);
                    if (updatedMessagesResponse.ok) {
                        const updatedMessagesData = await updatedMessagesResponse.json();
                        setMessages(updatedMessagesData.messages);
                    } else {
                        console.error('Failed to fetch updated messages. Server returned:', updatedMessagesResponse.status);
                    }

                    setLoadingMessage(false);
                }, 1000);
            } else {
                console.error('Failed to send message. Server returned:', response.status);
                setLoadingMessage(false);
            }
        } catch (error) {
            console.error('Error during fetch:', error);
            setLoadingMessage(false);
        }
    };

    const deleteMessage = async (messageId) => {
        try {
            const loggedInUserId = user ? user._id : null;

            if (!loggedInUserId) {
                console.error('User not authenticated');
                return;
            }

            const isUserAuthorized = messages.some((message) => message.author._id === loggedInUserId);

            if (!isUserAuthorized) {
                console.error('User is not authorized to delete this message');
                return;
            }

            const response = await fetch(`https://jetraduis-server.vercel.app/api/forum/discussions/${id}/messages/${messageId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const updatedMessages = messages.filter((message) => message._id !== messageId);
                setMessages(updatedMessages);
            } else {
                console.error('Failed to delete message');
            }
        } catch (error) {
            console.error('Error while deleting message', error);
        }
    };

    const sortedAndFilteredDiscussions = messages
        .filter((message) => message.content.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            switch (sortBy) {
                case 'date':
                    return new Date(b.createdAt) - new Date(a.createdAt);
                case 'author':
                    return a.author.fullName.localeCompare(b.author.fullName);
                default:
                    return 0;
            }
        });

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    return (
        <div>
            <h2>{t('Discussion id')}: {discussion.title}</h2>
            {
                loading ? (
                    <p>{t('Chargement')}...</p>
                ) : (
                    <div>
                        <div>
                            {discussion && discussion.author && (
                                <>
                                    <div className='author_discussion'>
                                        {t('Ecrit par')} :
                                        <div className='author_discussion_sub author_part'>
                                            <img src={`${discussion.author.photo}`} alt="photoProfil" className='photoProfil' />
                                            <p>{discussion.author.fullName} on {formatDate(discussion.createdAt)}</p>
                                        </div>
                                        <div className='author_discussion_content_part '>
                                            <p>{discussion.content}</p>
                                        </div>
                                    </div>

                                    <table className='table_forum'>
                                        <thead>
                                            <tr>
                                                <th colSpan="3">
                                                    <div className='search_filter'>
                                                        <div>
                                                            {t('Rechercher')} :
                                                            <input
                                                                type="text"
                                                                value={searchTerm}
                                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                                aria-label={t('Rechercher des messages')}
                                                            />
                                                        </div>
                                                        <div>
                                                            {t('Trier par')} :
                                                            <select
                                                                value={sortBy}
                                                                onChange={(e) => setSortBy(e.target.value)}
                                                                aria-label={t('Trier les messages')}
                                                            >
                                                                <option value="date">{t('Date')}</option>
                                                                <option value="author">{t('Auteur')}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadingMessage ? (
                                                <tr>
                                                    <td colSpan="3">{t('Envoi du message en cours')} ...</td>
                                                </tr>
                                            ) : (
                                                sortedAndFilteredDiscussions.map((message) => (
                                                    <tr key={message._id}>
                                                        <td>
                                                            {message.author && (
                                                                <>
                                                                    <img src={`${message.author.photo}`} alt="photoProfil" className='photoProfil' />
                                                                    <p>{message.author.fullName}</p>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <p>{message.content}</p>
                                                        </td>
                                                        <td>
                                                            {t('Ecrit par')} : {message.author.fullName} on {formatDate(message.createdAt)}
                                                            <p>
                                                                {user && (user._id === message.author?._id || user.moderator) && (
                                                                    <button
                                                                        onClick={() => deleteMessage(message._id)}
                                                                        className='delete_button'
                                                                        aria-label={t(`Supprimer le message de ${message.author.fullName}`)}
                                                                    >
                                                                        {t('Supprimer le message')}
                                                                    </button>
                                                                )}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>
                )}
            <div className="create-discussion-container">
                <h3>{t(`Créer un nouveau message`)}</h3>
                <textarea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder={`${t('Saisissez votre message')}...`}
                    aria-label="New message content"
                />
                <button onClick={sendMessage} aria-label={t('Envoyer le message')}>{t('Envoyer le message')}</button>
            </div>
        </div>
    );
};

export default Discussion;
