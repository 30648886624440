//Home.js
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
import './css/Home.css';
import juridique from '../madia/images/juridique.svg'
import generale from '../madia/images/traduction_generale.png'
import localization from '../madia/images/Website-localization.png';
import interprete from '../madia/images/interprete.png';
import relecture from '../madia/images/relecture.png';
import legalisation from '../madia/images/legalisation.png';
import technic from '../madia/images/technic.png';
import subtitle from '../madia/images/subtitle.png';
import redaction from '../madia/images/redaction.png';
import interpretationSim from '../madia/images/interpretationSim.png';
import learning from '../madia/images/learning.png';
import editing from '../madia/images/editing.png';
const Home = () => {

  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const languageContext = useContext(LanguageContext);
  const t = (key) => translations[key] || key;
  const { translations } = languageContext || {};

  // Fonction pour basculer l'affichage des services
  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };
  return (
    <div className="home-container">
      <section className="hero-section">
        <h1 id="homeHeading">{t('Bienvenue sur notre Service de Traduction en Ligne')} </h1>
        <Link to="/all-services" className="cta-button" aria-label={t('Découvrez Nos Services')}>
          {t('Commandez votre traduction maintenant !')}
        </Link>
      </section>

      <section className="about-section">
        <Link to={`/about`} className="about-content">
          <h2>{t('À Propos de Nous')}</h2>
        </Link>
        <p>{t('Nous sommes une équipe passionnée de traducteurs dédiés à offrir des services linguistiques de haute qualité. Avec des années d\'exp\u00E9rience, nous sommes votre choix de confiance pour toutes vos traductions')}.</p>
        <div className="about-image">
          {/* Image illustrative */}
        </div>
        <button onClick={toggleServices} className="toggle-services-btn" aria-label={isServicesOpen ? t('Cacher les Services') : t('Afficher les Services')}>
          {isServicesOpen ? t('Cacher les Services') : t('Afficher les Services')}
        </button>
        {isServicesOpen && (
          <ul className='service-item' aria-labelledby="servicesListHeading">
            <Link to={`/services/?type=certified`} className="service-item-link">
              <li className="service-item">
                <img src={juridique} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3>{t('Traduction Certifiée')}</h3>
                  <p>{t('Obtenez des traductions certifiées pour vos documents officiels')}</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=general`} className="service-item-link">
              <li className="service-item">
                <img src={generale} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="generalService">{t('Traduction Générale')}</h3>
                  <p>{t('Traduisez des textes généraux avec précision et qualité')}</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=localization`} className="service-item-link">
              <li className="service-item">
                <img src={localization} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="localizationService">{t('Localisation')}</h3>
                  <p>{t('Adaptez vos contenus aux marchés et cultures locaux')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=interpretation`} className="service-item-link">
              <li className="service-item">
                <img src={interprete} alt="" className='icone-services' />
                <div className='description-cart-services'>
                  <h3 id="interpretationService">{t('Interprétation')}</h3>
                  <p>{t('Services d\'interprétation pour conférences et réunions')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=proofreading`} className="service-item-link">
              <li className="service-item">
                <img src={relecture} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="proofreadingService">{t('Relecture')}</h3>
                  <p>{t('Améliorez la qualité et la précision de vos documents')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=legalization`} className="service-item-link">
              <li className="service-item">
                <img src={legalisation} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="legalizationService">{t('Légalisation')}</h3>
                  <p>{t('Assistance dans la légalisation de documents pour mairies, chambres de commerce et ambassades')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=technical`} className="service-item-link">
              <li className="service-item">
                <img src={technic} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="technicalService">{t('Traduction Technique')}</h3>
                  <p>{t('Traductions spécialisées pour documents techniques, scientifiques et médicaux')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=subtitling`} className="service-item-link">
              <li className="service-item">
                <img src={subtitle} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="subtitlingService">{t('Sous-titrage')}</h3>
                  <p>{t('Services de sous-titrage pour vidéos, films et conférences')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=contentwriting`} className="service-item-link">
              <li className="service-item">
                <img src={redaction} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="contentwritingService">{t('Rédaction de Contenu')}</h3>
                  <p>{t('Création de contenus écrits originaux pour sites web, blogs et marketing')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=simultaneousinterpretation`} className="service-item-link">
              <li className="service-item">
                <img src={interpretationSim} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="simultaneousinterpretationService">{t('Interprétation Simultanée')}</h3>
                  <p>{t('Interprétation en temps réel pour conférences internationales et événements d\'affaires')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=editing`} className="service-item-link">
              <li className="service-item">
                <img src={editing} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="editingService">{t('Correction et Édition')}</h3>
                  <p>{t('Services de correction et d\'édition pour garantir la qualité de vos textes')}.</p>
                </div>
              </li>
            </Link>
            <Link to={`/services/?type=languagecourses`} className="service-item-link">
              <li className="service-item">
                <img src={learning} alt="" className='icone-services' />
                <div className='description-cart-services'>                  <h3 id="languagecoursesService">{t('Formation Linguistique')}</h3>
                  <p>{t('Cours de langues personnalisés pour professionnels et particuliers')}.</p>
                </div>
              </li>
            </Link>
          </ul>
        )}
      </section>





      <section className="collaborate-section">
        <div className="collaborate-content">
          <h2>{t('Collaborez avec Nous')}</h2>
          <p>{t('Êtes-vous un traducteur professionnel? Rejoignez notre équipe et étendez votre carrière. Nous offrons des opportunités passionnantes pour les traducteurs talentueux')}.</p>
          <Link to="/signup/translator" className="cta-button" aria-label={t('Devenez Traducteur')}>
            {t('Devenez Traducteur')}
          </Link>
        </div>
        <div className="collaborate-image">
          {/* Image illustrative */}
        </div>
      </section>

      <section className="contact-section">
        <h2 style={{ color: 'white' }}>{t('Contactez-nous')}</h2>
        <p>{t('Vous avez des questions? Besoin d\'aide pour vos traductions? Notre équipe de service client est disponible 24/7 pour vous assister')}</p>
        <Link to="/contact" className="cta-button" aria-label={t('Nous Contacter')}>
          {t('Nous Contacter')}
        </Link>
      </section>

      <section className="social-section">
        <h2>{t('Restons Connectés')}</h2>
        <p>{t('Suivez-nous sur les réseaux sociaux pour les dernières mises à jour, des offres spéciales et plus encore')}.</p>
        <div className="social-links">
          <a href="lien_vers_facebook" aria-label="Facebook">{t('Facebook')}</a>
          <a href="lien_vers_twitter" aria-label="Twitter">{t('Twitter')}</a>
          <a href="lien_vers_instagram" aria-label="Instagram">{t('Instagram')}</a>
        </div>
      </section>
    </div >
  );
};

export default Home;
