//Header.js
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonSearch } from './Button';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import logo from '../madia/images/logoJeTraduis.png';
import { LoaderContext } from '../contexts/LoaderContext';
import BurgerMenu from './BurgerMenu';
import { FaPhoneAlt } from "react-icons/fa";
import LanguageSwitcher from './LanguageSwitcher';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const { admin } = useContext(AuthContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const languageContext = useContext(LanguageContext);
  const { translations, changeLanguage } = languageContext || {};
  const [searchTerm, setSearchTerm] = useState('');
  const { loading } = useContext(LoaderContext);
  const t = (key) => translations[key] || key;

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Erreur lors de la déconnexion :', error);
    }
  };
  const userMenuItems = [
    { label: 'Home', link: '/' },
  ];
  const handleMenuItemClick = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header" role="banner">
      {loading && <div className="loader"></div>}

      <div className="header-top">

        <div className='element-header'>
          <Link to="/" aria-label="Home"> <img src={logo} alt="Logo" className="logo" /></Link>
        </div>
        <div className='header-phone-language'>
          <LanguageSwitcher />
          <div className='element-header'><Link to="./contact" aria-label="contact"><FaPhoneAlt alt="icon_phone" className="phone-icon" /></Link></div>
        </div>
      </div>
      <BurgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />

      <nav className={`header-nav ${isMenuOpen ? 'open' : 'close'} ${userMenuItems.length > 1 ? 'multiple-items' : ''}`} role="navigation" aria-label="Main Navigation">
        {user && (
          <>
            <ul className='bonjourEtLogout' role="menu">
              <li className='bonjour' role="menuitem" onClick={handleMenuItemClick}>{t('Bonjour')}, {user.fullName}</li>
              <li role="menuitem" onClick={handleMenuItemClick}><Button onClick={handleLogout} text={t("Se Déconnecter")} /></li>
            </ul>
            <ul className='navMenu' role="menu">
              <li role="menuitem" onClick={handleMenuItemClick}><Link to="/">{t('Accueil')}</Link></li>
              {user.role === 'client' && (
                <li role="menuitem" onClick={handleMenuItemClick}><Link to={`/userDashboard/${user._id}`}>{t('Tableau de bord')}</Link></li>
              )}
              {user.role === 'translator' && (
                <>
                  <li role="menuitem" onClick={handleMenuItemClick}><Link to={`/translatorDashboard/${user._id}`}>{t('Tableau de bord')}</Link></li>
                  <li role="menuitem" onClick={handleMenuItemClick}><Link to={`/../Forum/translator/${user._id}`}>{t('Profil')}</Link></li>
                </>
              )}
              {user.role === 'admin' && (
                <li role="menuitem" onClick={handleMenuItemClick}><Link to={`admin/dashboard/${user._id}`}>{t('Tableau de bord')}</Link></li>
              )}
              {user.role !== 'translator' && (
                <>
                  <li role="menuitem" onClick={handleMenuItemClick}><Link to="/cart">{t('Panier')}</Link></li>
                  <li role="menuitem" onClick={handleMenuItemClick}><Link to="/all-services">{t('Services')}</Link></li>
                </>
              )}
              <li role="menuitem" onClick={handleMenuItemClick}><Link to="/contact">{t('Contact')}</Link></li>
              <li role="menuitem" onClick={handleMenuItemClick}><Link to="/about">{t('À Propos')}</Link></li>
              <li role="menuitem" onClick={handleMenuItemClick}><Link to={'/Forum'}>{t('Forum')}</Link></li>
            </ul>
          </>
        )}
        {admin && (
          <>
            <ul className='bonjourEtLogout' role="menu">
              <li className='bonjour' role="menuitem">{t('Bonjour')}, {admin.fullName}</li>
              <li role="menuitem" onClick={handleMenuItemClick}><Button onClick={handleLogout} text={t("Se Déconnecter")} /></li>
            </ul>
            <ul className='navMenu' role="menu">
              <li role="menuitem" onClick={handleMenuItemClick}><Link to="/">{t('Accueil')}</Link></li>
              {admin.role === 'admin' && (
                <li role="menuitem" onClick={handleMenuItemClick}><Link to={`/admin/dashboard/${admin._id}`}>{t('Tableau de bord')}</Link></li>
              )}
            </ul>
          </>
        )}
        {!user && !admin && (
          <ul className='navMenu' role="menu">
            <li role="menuitem" onClick={handleMenuItemClick}><Link to="/login">{t('Connexion')}</Link></li>
            <li role="menuitem" onClick={handleMenuItemClick}><Link to="/signup">{t('Inscription')}</Link></li>
            <li role="menuitem" onClick={handleMenuItemClick}><Link to={'/Forum'}>{t('Forum')}</Link></li>
            <li role="menuitem" onClick={handleMenuItemClick}><Link to="/all-services">{t('Services')}</Link></li>
            <li role="menuitem" onClick={handleMenuItemClick}><Link to="/about">{t('À Propos')}</Link></li>
            <li role="menuitem" onClick={handleMenuItemClick}><Link to="/cart">{t('Panier')}</Link></li>
          </ul>
        )}
      </nav>
    </header>
  );
};


export default Header;