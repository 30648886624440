// OrderConfirmation.jsx

import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';

const OrderConfirmation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get('orderId');
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;



  useEffect(() => {
    // Call your update function here
    const updateOrderStatus = async () => {
      try {
        const response = await fetch(`https://jetraduis-server.vercel.app/api/stripe/${orderId}/update-status`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: 'pending' }), // Update the status as needed
        });

        if (!response.ok) {
          throw new Error('Error updating order status');
        }

        // Handle success if needed
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    };

    // Call the update function when the component mounts
    if (orderId) {
      updateOrderStatus();
    }
  }, [orderId]);

  return (
    <div role="region" aria-labelledby="orderConfirmationHeading">
      <h2 id="orderConfirmationHeading">{t('Confirmation de commande')}</h2>
      {orderId ? (
        <div>
          {t('Votre commande avec le numéro de commande')} :{' '}
          <p aria-live="polite">{orderId}</p> {t('a été confirmée. Merci')}!
        </div>
      ) : (
        <p>{t('Chargement')}...</p>
      )}
      <p>
        <Link to="/services" role="link">
          {t('Retour aux services')}
        </Link>
      </p>
    </div>
  );
};

export default OrderConfirmation;
