// DiscussionList.js
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import './Forum.css';
import { LanguageContext } from '../../../contexts/LanguageContext';

const DiscussionList = () => {
    const [discussions, setDiscussions] = useState([]);
    const [newDiscussionTitle, setNewDiscussionTitle] = useState('');
    const [newDiscussionContent, setNewDiscussionContent] = useState('');
    const { user } = useContext(AuthContext);
    const [sortBy, setSortBy] = useState('date');
    const [searchTerm, setSearchTerm] = useState('');
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;

    useEffect(() => {
        const fetchDiscussions = async () => {
            try {
                const response = await fetch(`https://jetraduis-server.vercel.app/api/forum/discussions`, {
                    credentials: 'include',
                });
                if (response.ok) {
                    const data = await response.json();
                    setDiscussions(data);
                } else {
                    console.error('Failed to fetch discussions');
                }
            } catch (error) {
                console.error('Network error while fetching discussions', error);
            }
        };
        fetchDiscussions();
    }, [user]);

    const createNewDiscussion = async () => {
        try {
            const response = await fetch(`https://jetraduis-server.vercel.app/api/forum/new-discussion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    credentials: 'include',
                },
                body: JSON.stringify({ title: newDiscussionTitle, content: newDiscussionContent, author: user }),
            });

            if (response.ok) {
                const updatedDiscussions = await response.json();
                setDiscussions(updatedDiscussions);
                setNewDiscussionTitle('');
                setNewDiscussionContent('');
            } else {
                console.error('Failed to create a new discussion');
            }
        } catch (error) {
            console.error('Network error while creating a new discussion', error);
        }
    };

    const sortedAndFilteredDiscussions = discussions
        .filter(discussion => discussion.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            switch (sortBy) {
                case 'date':
                    return new Date(b.createdAt) - new Date(a.createdAt);
                case 'author':
                    return a.author.fullName.localeCompare(b.author.fullName);
                case 'title':
                    return a.title.localeCompare(b.title);
                default:
                    return 0;
            }
        });

    const deleteDiscussion = async (discussionId) => {
        try {
            const loggedInUserId = user ? user._id : null;

            if (!loggedInUserId) {
                console.error('User not authenticated');
                return;
            }

            const isUserAuthorized = discussions.some((discussion) => discussion.author._id === loggedInUserId);

            if (!isUserAuthorized) {
                console.error('User is not authorized to delete this discussion');
                return;
            }

            const response = await fetch(`https://jetraduis-server.vercel.app/api/forum/discussions/delete/${discussionId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const updatedDiscussions = discussions.filter((discussion) => discussion._id !== discussionId);
                setDiscussions(updatedDiscussions);
            } else {
                console.error('Failed to delete discussion');
            }
        } catch (error) {
            console.error('Error while deleting discussion', error);
        }
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    return (
        <div>
            <h2>{t('Forum')}</h2>
            <table className='table_forum_list'>
                <thead>
                    <tr>
                        <th colSpan="2">
                            <label htmlFor="searchInput">{t('Rechercher')}:</label>
                            <input
                                type="text"
                                id="searchInput"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </th>
                        <th colSpan="2">
                            <label htmlFor="sortBySelect">{t('Trier par')} :</label>
                            <select
                                id="sortBySelect"
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                <option value="date">{t('Date')}</option>
                                <option value="author">{t('Auteur')}</option>
                                <option value="title">{t('Titre')}</option>
                            </select>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedAndFilteredDiscussions.map((discussion) => (
                        <tr key={discussion._id}>
                            <td>
                                <img src={`${discussion.author.photo}`} alt="photoProfil" className='photoProfil' />
                                {discussion.author.fullName}
                            </td>
                            <td>
                                <Link to={`/forum/discussions/${discussion._id}`}>
                                    <strong>{discussion.title}</strong>
                                </Link>
                                <p>{discussion.content}</p>
                                {user && (user._id === discussion.author?._id || user.moderator) && (
                                    <button
                                        onClick={() => deleteDiscussion(discussion._id)}
                                        className='delete_button'
                                        aria-label={t(`Supprimer la discussion intitulée"${discussion.title}"`)}
                                    >
                                        Delete Discussion
                                    </button>
                                )}
                            </td>
                            <td>
                                {discussion.author.createdAt && (
                                    <p>
                                        {t('Ecrit par')} : {discussion.author.fullName} {t('Le')} {formatDate(discussion.createdAt)}
                                    </p>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="create-discussion-container">
                <h3>{t('Créer une nouvelle discussion')}</h3>
                <input
                    type="text"
                    placeholder={t('Saisissez le titre de la discussion')}
                    value={newDiscussionTitle}
                    onChange={(e) => setNewDiscussionTitle(e.target.value)}
                    aria-label={t('Nouveau titre de discussion')}
                />
                <textarea
                    placeholder={t('Saisissez le contenu de la discussion')}
                    value={newDiscussionContent}
                    onChange={(e) => setNewDiscussionContent(e.target.value)}
                    aria-label={t('Nouveau contenu de la discussion')}
                />
                <button onClick={createNewDiscussion} aria-label={t('Créer une discussion')}>
                    {t('Créer une discussion')}
                </button>
            </div>
        </div >
    );
};

export default DiscussionList;
