// RecoverPassword.jsx
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { LanguageContext } from '../contexts/LanguageContext';

const RecoverPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://jetraduis-server.vercel.app/api/users/recover-password-user`, { email });
            setMessage(response.data.message);
            setEmailSent(true);
        } catch (error) {
            if (error.response && error.response.data.message) {
                setMessage(error.response.data.message);
            } else {
                setMessage('Failed to send password reset email.');
            }
        }
    };


    return (
        <div>
            <h1>{t('Réinitialiser le mot de passe')}</h1>
            {!emailSent ? (
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">{t('Envoyer l\'e-mail de réinitialisation')}
                    </button>
                </form>
            ) : (
                <p className='messageSuccessful'>{message || 'Email envoyé avec succès. Veuillez vérifier votre boîte de réception.'}</p>
            )}
        </div>
    );
}

export default RecoverPassword;
