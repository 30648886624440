//Contact.js 
import React, { useState, useContext } from 'react';
import { FaFacebook, FaXTwitter, FaInstagram } from 'react-icons/fa6';
import '../pages/css/Contact.css'
import { LanguageContext } from '../contexts/LanguageContext';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className='contact-container'>
      <h1>{t('À venir')}</h1>
      <h2>{t('Contactez-nous')}</h2>
      <p>{t('Vous avez une question ou souhaitez nous contacter ? Envoyez-nous un message en utilisant le formulaire ci-dessous')}</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">{t('Nom')} :</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="email">{t('E-mail')} :</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="message">{t('Message')} :</label>
          <textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} required />
        </div>
        <button type="submit">{t('Envoyer')}</button>
      </form>
      <div>
        <h3>{t('Restez connecté')}</h3>
        <p>{t('Suivez-nous sur les réseaux sociaux pour des mises à jour et plus encore')}</p>
        <div className='social-links'>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaXTwitter />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
