import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import './css/AllServices.css';
import juridique from '../madia/images/juridique.svg'
import generale from '../madia/images/traduction_generale.png'
import localization from '../madia/images/Website-localization.png';
import interprete from '../madia/images/interprete.png';
import relecture from '../madia/images/relecture.png';
import legalisation from '../madia/images/legalisation.png';
import technic from '../madia/images/technic.png';
import subtitle from '../madia/images/subtitle.png';
import redaction from '../madia/images/redaction.png';
import interpretationSim from '../madia/images/interpretationSim.png';
import learning from '../madia/images/learning.png';
import editing from '../madia/images/editing.png';


const ServicesBannieres = () => {
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const languageContext = useContext(LanguageContext);
    const t = (key) => translations[key] || key;
    const { language, translations, changeLanguage } = languageContext || {};
    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
    };

    return (
        <div>
            <ul className='all-service-item-container' role="list" aria-labelledby="servicesListHeading">
                <div className="all-service-item">
                    <Link to={`/services-certified/?type=certified`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Traduction Certifiée')}</h2>
                                <img src={juridique} alt="" className='icone-all-services' />
                                <p>{t('Obtenez des traductions certifiées pour vos documents officiels')}</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-general/?type=general`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Traduction Générale')}</h2>
                                <img src={generale} alt="" className='icone-all-services' />
                                <p>{t('Traduisez des textes généraux avec précision et qualité')}</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-localization`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Localisation')}</h2>
                                <img src={localization} alt="" className='icone-all-services' />
                                <p>{t('Adaptez vos contenus aux marchés et cultures locaux')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-interpretation`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Interprétation')}</h2>
                                <img src={interprete} alt="" className='icone-all-services' />
                                <p>{t('Services d\'interprétation pour conférences et réunions')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-proofreading`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Relecture')}</h2>
                                <img src={relecture} alt="" className='icone-all-services' />
                                <p>{t('Améliorez la qualité et la précision de vos documents')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-legalization`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Légalisation')}</h2>
                                <img src={legalisation} alt="" className='icone-all-services' />
                                <p>{t('Assistance dans la légalisation de documents pour différentes autorités')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-technical`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Traduction Technique')}</h2>
                                <img src={technic} alt="" className='icone-all-services' />
                                <p>{t('Traductions spécialisées pour documents techniques, scientifiques et médicaux')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-subtitling`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Sous-titrage')}</h2>
                                <img src={subtitle} alt="" className='icone-all-services' />
                                <p>{t('Services de sous-titrage pour vidéos, films et conférences')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-contentwriting`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Rédaction de Contenu')}</h2>
                                <img src={redaction} alt="" className='icone-all-services' />
                                <p>{t('Création de contenus écrits originaux pour sites web, blogs et marketing')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-simultanious`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Interprétation Simultanée')}</h2>
                                <img src={interpretationSim} alt="" className='icone-all-services' />
                                <p>{t('Interprétation en temps réel pour conférences internationales et événements d\'affaires')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-editing`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Correction et Édition')}</h2>
                                <img src={editing} alt="" className='icone-all-services' />
                                <p>{t('Services de correction et d\'édition pour garantir la qualité de vos textes')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
                <div className="all-service-item">
                    <Link to={`/services-languages`} className="all-service-item-link">
                        <li>
                            <div>
                                <h2>{t('Formation Linguistique')}</h2>
                                <img src={learning} alt="" className='icone-all-services' />
                                <p>{t('Cours de langues personnalisés pour professionnels et particuliers')}.</p>
                            </div>
                        </li>
                    </Link>
                </div>
            </ul>
        </div>
    )
}

export default ServicesBannieres