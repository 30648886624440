// Login.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import './css/Login.css';

function Login() {
  const { user } = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;

  if (user) {
    return (
      <div>
        <h2>{t('Connexion')}</h2>
        <p>{t('Vous êtes déjà connecté en tant que')} {user.fullName}</p>
      </div>
    );
  }

  return (
    <div>
      <h2>{t('Connexion')}</h2>
      <div className="login-options" role="group" aria-labelledby="loginOptionsHeading">
        <div className="login-option-card" role="listitem">
          <h3>{t('Client')}</h3>
          <p>{t('Connectez-vous en tant que client')}</p>
          <Link to="/user/login" role="button" aria-label={t('Connexion en tant que client')}>
            {t('Connexion')}
          </Link>
        </div>
        <div className="login-option-card" role="listitem">
          <h3>{t('Traducteur')}</h3>
          <p>{t('Connectez-vous en tant que traducteur')}</p>
          <Link to="/translator/login" role="button" aria-label={t('Connexion en tant que traducteur')}>
            {t('Connexion')}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
