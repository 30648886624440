//TranslatorDashboard.js
import React, { useContext, useState, useEffect, useCallback } from 'react';
import ISO6391 from 'iso-639-1';
import { AuthContext } from '../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/TranslatorDashboard.css';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
const TranslatorDashboard = () => {
  const { user } = useContext(AuthContext);
  const { userId, orderId } = useParams();
  const [translatorOrders, setTranslatorOrders] = useState([]);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [commentFormVisible, setCommentFormVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [proposedOrders, setProposedOrders] = useState([]);
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const [tableVisible, setTableVisible] = useState({});
  const [orderCommentsVisible, setOrderCommentsVisible] = useState({});

  const t = (key) => translations[key] || key;

  const toggleCommentsVisibility = (orderId) => {
    setOrderCommentsVisible((prevVisibility) => ({
      ...prevVisibility,
      [orderId]: !prevVisibility[orderId],
    }));
  };
  const toggleTableVisibility = (orderId) => {
    setTableVisible((prevVisibility) => ({
      ...prevVisibility,
      [orderId]: !prevVisibility[orderId],
    }));
  };
  const submitComment = async (orderId) => {
    try {
      if (!orderId) {
        console.error('Order ID is not defined.');
        return;
      }
      const fileName = selectedFile ? selectedFile.name : 'No File Selected';
      const response = await fetch(`https://jetraduis-server.vercel.app/api/translators/${userId}/orders/${orderId}/comment`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: userId, text: comment, file: 'fileComment-' + fileName }),
      });
      if (!response.ok) {
        throw new Error(`Error submitting comment for order ${orderId}`);
      }
      setComment('');
      setCommentFormVisible(false);
      fetchTranslatorOrders();
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchProposedOrders();
  }, []);
  const fetchProposedOrders = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `https://jetraduis-server.vercel.app/api/translators/${userId}/orders/proposed`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const filteredTrans = response.data.orders.filter(order =>
        order.proposedTranslator.some(pt => pt.translator === userId)
      );
      setProposedOrders(filteredTrans);

    } catch (error) {
      console.error(error);
    }
  };
  const fetchTranslatorOrders = useCallback(async () => {
    try {
      const response = await fetch(`https://jetraduis-server.vercel.app/api/translators/${userId}/orders`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching translator orders');
      }
      const data = await response.json();
      setTranslatorOrders(data.orders);
    } catch (error) {
      console.error(error);
    }
  }, [userId]);
  useEffect(() => {
    fetchTranslatorOrders();
  }, [fetchTranslatorOrders]);
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleFileUpload = async (orderId) => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile, selectedFile.name);

        const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/upload-file/${orderId}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        window.alert('fichier envoyé')
        fetchTranslatorOrders();
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setSelectedFile(null);
      }
    } else {
      console.error('Aucun fichier sélectionné');
    }
  };
  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  if (!user || user._id !== userId) {
    return <p>{t('Vous n\'avez pas la permission d\'accéder à ce tableau de bord')}.</p>;
  }
  return (
    <div className='user-dashboard'>
      <h1>{t('Tableau de bord')}</h1>
      {(user) ? (
        <div>
          <div>
            {
              translatorOrders.map((order) => (
                <tr key={order._id}>
                  <td>
                    <Link to={`/order/details/${order._id}`}>{t('Afficher les détails')}</Link>
                  </td>
                </tr>
              ))
            }
          </div>
          <div>
            <h2>{t('Commandes assignées')} :</h2>
            {translatorOrders
              .filter((order) => order.status === 'assigned')
              .map((order, index) => (

                <div key={order._id}>
                  <h3>
                    <th>{t('Numéro de commande')} : </th>
                    <td>{`Order ${index + 1}`}</td>
                  </h3>
                  <p>
                    <th>{t('Date')}</th>
                    <td>{formatDateTime(order.createdAt)}</td>
                  </p>
                  <button onClick={() => toggleTableVisibility(order._id)} className='dashboard-button'>
                    {tableVisible[order._id] ? t('Masquer la commande') : t('Afficher la commande')}
                  </button>
                  {tableVisible[order._id] && (
                    <div>
                      <table className='table-orders'>
                        <tr>
                          <th>{t('Numéro de commande')}</th>
                          <td>{order._id}</td>
                        </tr>
                        <tr>
                          <th>{t('Langue source')}</th>
                          <td>{order.sourceLanguage}</td>
                        </tr>
                        <tr>
                          <th>{t('Langue cible')}</th>
                          <td>{order.targetLanguage}</td>
                        </tr>
                        <tr>
                          <th>{t('Prix')}</th>
                          <td>{order.amountTranslator} € </td>
                        </tr>
                        <tr>
                          <th>{t('Fichiers à traduire')}</th>
                          <td>
                            <a
                              href={`https://jetraduis-server.vercel.app/api/translators/${userId}/client-files?orderNumber=${order.orderNumber}`}
                              download={`client-files-${userId}.zip`}
                            >
                              {t('Télécharger')}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('Envoyer les fichiers traduits')}</th>
                          <td className='cellule-file'>
                            <input type="file" onChange={handleFileChange} />
                            <button onClick={() => handleFileUpload(order._id)} className='button-file-comment'>{t('Envoyer')}</button>
                          </td>
                        </tr>
                      </table>
                      <div>
                        {/* commennnttaiiiiiiiiiiiiires */}
                        <div>
                          <button onClick={() => toggleCommentsVisibility(order._id)} className='dashboard-button-comment'>
                            {orderCommentsVisible[order._id] ? t('Masquer les commentaires') : t('Afficher les commentaires')}
                          </button>
                          {orderCommentsVisible[order._id] && (
                            <div className='comments'>
                              <div key={order._id} className="comment-container">
                                <div className='comment-unity'>


                                  {order._id}
                                  {order.commentFile && order.commentFile.length > 0 ? (
                                    <div >
                                      <h3>{t('Fchier commentaire')} :</h3>
                                      <ul>
                                        {order.commentFile.map((file, index) => (
                                          <li key={index} className='file-comment'>
                                            {decodeURIComponent(file)}
                                            <button className='button-file-comment'
                                              onClick={() => {
                                                window.location.href = `https://jetraduis-server.vercel.app/api/translators/${userId}/comment-files?orderNumber=${order.orderNumber}&fileIndexes=${index}`;
                                              }}
                                            >
                                              {t('Télécharger')}
                                            </button>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  ) : (
                                    <p>Aucun fichier</p>
                                  )}
                                </div>
                                <div>
                                  <h3>{t('Commentaires')} :</h3>
                                  <div>
                                    {order.comment?.filter((comment) => comment.approved)?.map((comment) => (
                                      <div key={comment._id}>
                                        {comment.user &&
                                          <div>
                                            <h3>{formatDateTime(comment.createdAt)}</h3>
                                            <p >
                                              <h4 className='user-name-comment'> {comment.fullName} :
                                              </h4>{comment.text}
                                            </p>
                                          </div>
                                        }
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div>
                                  <div className='add-comment'>
                                    <h3>{t('Ajouter un commentaire')}</h3>
                                    <textarea
                                      rows="4"
                                      cols="50"
                                      placeholder={t('Mettez votre commentaire')}
                                      value={comment}
                                      onChange={(e) => setComment(e.target.value)}
                                    ></textarea>
                                    <button onClick={() => submitComment(order._id)} className='button-file-comment'>{t('Soumettre le commentaire')}</button>
                                  </div>

                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                  )}
                </div>
              ))}
          </div>
          <div>
            <div>
              <h2>{t('Toutes les commandes')} :</h2>

              {translatorOrders
                .filter((order) => order.status === 'completed' || order.status === 'translated' || order.status === 'cancelled')
                .map((order, index) => (
                  <div key={order._id}>
                    <h3>
                      <th>{t('Numéro de commande')} : </th>
                      <td>{`Order ${index + 1}`}</td>
                    </h3>
                    <p>
                      <th>{t('Date')}</th>
                      <td>{formatDateTime(order.createdAt)}</td>
                    </p>
                    <button onClick={() => toggleTableVisibility(order._id)} className='dashboard-button'>
                      {tableVisible[order._id] ? t('Masquer la commande') : t('Afficher la commande')}
                    </button>
                    {tableVisible[order._id] && (
                      <div>
                        <table className='table-orders'>
                          <tr>
                            <th>{t('Numéro de commande')}</th>
                            <td>{order._id}</td>
                          </tr>
                          <tr>
                            <th>{t('Langue source')}</th>
                            <td>{order.sourceLanguage}</td>
                          </tr>
                          <tr>
                            <th>{t('Langue cible')}</th>
                            <td>{order.targetLanguage}</td>
                          </tr>
                          <tr>
                            <th>{t('Prix')}</th>
                            <td>{order.amountTranslator} € </td>
                          </tr>
                          <tr>
                            <th>{t('Fichiers à traduire')}</th>
                            <td>
                              <a
                                href={`https://jetraduis-server.vercel.app/api/translators/${userId}/client-files?orderNumber=${order.orderNumber}`}
                                download={`client-files-${userId}.zip`}
                              >
                                {t('Télécharger')}
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <th>{t('Fichier traduit')}</th>
                            <td>
                              <input type="file" onChange={handleFileChange} />
                              <button onClick={() => handleFileUpload(order._id)}>{t('Envoyer')}</button>
                            </td>
                          </tr>
                          <tr>
                            <th>{t('Etat')}</th>
                            <td>{order.status}</td>
                          </tr>
                        </table>
                        <div>
                          {/* commennnttaiiiiiiiiiiiiires */}
                          <div>
                            <button onClick={() => toggleCommentsVisibility(order._id)} className='dashboard-button'>
                              {orderCommentsVisible[order._id] ? t('Masquer les commentaires') : t('Afficher les commentaires')}
                            </button>
                            {orderCommentsVisible[order._id] && (
                              <div className='comments'>
                                {translatorOrders.map((order, index) => (
                                  <div key={order._id} className="comment-container">
                                    <div className='comment-unity'>
                                      {order._id}
                                      {order.commentFile && order.commentFile.length > 0 ? (
                                        <div >
                                          <h3>{t('Fchier commentaire')} :</h3>
                                          <ul>
                                            {order.commentFile.map((file, index) => (
                                              <li key={index} className='file-comment'>
                                                {decodeURIComponent(file)}
                                                <button className='button-file-comment'
                                                  onClick={() => {
                                                    window.location.href = `https://jetraduis-server.vercel.app/api/translators/${userId}/comment-files?orderNumber=${order.orderNumber}&fileIndexes=${index}`;
                                                  }}
                                                >
                                                  {t('Télécharger')}
                                                </button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ) : (
                                        <p>Aucun fichier</p>
                                      )}
                                    </div>
                                    <div>
                                      <h3>{t('Commentaires')} :</h3>
                                      <div>
                                        {order.comment?.filter((comment) => comment.approved)?.map((comment) => (
                                          <div key={comment._id}>
                                            {comment.user &&
                                              <div>
                                                <h3>{formatDateTime(comment.createdAt)}</h3>
                                                <p >
                                                  <h4 className='user-name-comment'> {comment.fullName} :
                                                  </h4>{comment.text}
                                                </p>
                                              </div>
                                            }
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    <div>
                                      <div className='add-comment'>
                                        <h3>{t('Ajouter un commentaire')}</h3>
                                        <textarea
                                          rows="4"
                                          cols="50"
                                          placeholder={t('Mettez votre commentaire')}
                                          value={comment}
                                          onChange={(e) => setComment(e.target.value)}
                                        ></textarea>
                                        <button onClick={() => submitComment(order._id)} className='button-file-comment'>{t('Soumettre le commentaire')}</button>
                                      </div>
                                      <h3>{t('Envoyer les fichiers traduits')}</h3>
                                      <div className='cellule-file'>
                                        <input type="file" onChange={handleFileChange} />
                                        <button onClick={() => handleFileUpload(order._id)} className='button-file-comment'>{t('Envoyer')}</button>
                                      </div>
                                    </div>
                                  </div>
                                )
                                )}
                              </div>
                            )}
                          </div>

                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <p>{t('Veuillez vous connecter pour accéder au tableau de bord')}.</p>
      )
      }
    </div >

  );
};

export default TranslatorDashboard;
