// RoomContext.jsx
import React, { createContext, useState } from 'react';

export const RoomContext = createContext();

export const RoomProvider = ({ children }) => {
    const [roomId, setRoomId] = useState('');

    const updateRoomId = (newRoomId) => {
        setRoomId(newRoomId);
    };

    return (
        <RoomContext.Provider value={{ roomId, updateRoomId }}>
            {children}
        </RoomContext.Provider>
    );
};
