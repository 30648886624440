import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageUploader from '../common/ImageUploader';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import ISO6391 from 'iso-639-1';
import './UserProfil.css';
import { LanguageContext } from '../../../contexts/LanguageContext';

const UserProfile = () => {
    const { id: userId } = useParams();
    const { user } = useContext(AuthContext);
    const [translator, setTranslator] = useState(null);
    const [error, setError] = useState(null);
    const [workingLanguages, setWorkingLanguages] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [isSwornTranslator, setIsSwornTranslator] = useState(false);
    const [swornTranslatorDoc, setSwornTranslatorDoc] = useState(null);
    const [identificationDoc, setIdentificationDoc] = useState(null);
    const [isUpdateProfileVisible, setIsUpdateProfileVisible] = useState(false);
    const [selectedSpecialization, setSelectedSpecialization] = useState('');
    const [selectedDegree, setSelectedDegree] = useState('');
    const [customDegree, setCustomDegree] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [presentations, setPresentations] = useState('');
    const [photo, setPhoto] = useState('');
    const [experiences, setExperiences] = useState(['']);
    const [interests, setInterests] = useState('');
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [updatedUser, setUpdatedUser] = useState(null);
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;
    const languages = ISO6391.getAllNames();

    const handlePresentationsChange = (event) => {
        setPresentations(event.target.value);
    };
    const handlePhotoChange = (event) => {
        setPhoto(event.target.value);
    };
    const handleExperiencesChange = (event) => {
        setExperiences(event.target.value);
    };
    const handleInterestsChange = (event) => {
        setInterests(event.target.value);
    };
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };
    const addLanguage = () => {
        const languageName = ISO6391.getName(selectedLanguage);
        if (languageName && !workingLanguages.includes(languageName)) {
            setWorkingLanguages([...workingLanguages, languageName]);
        }
    };
    const removeLanguage = (languageNameToRemove) => {
        const updatedLanguages = workingLanguages.filter(lang => lang !== languageNameToRemove);
        setWorkingLanguages(updatedLanguages);
    };

    const DEGREES_OPTIONS = [
        "Postgraduate Diploma",
        "Bachelor",
        "Master",
        "Doctorate"
    ];
    const SPECIALIZATIONS = [
        "Technique",
        "Médicale et Pharmaceutique",
        "Juridique et Légale",
        "Financière et Économique",
        "Contenus Web Marketing",
        "Localisation de Logiciels et de Jeux Vidéo",
        "Littéraire",
        "Audiovisuelle",
        "Scientifique",
        "Documents Officiels et Certifiés",
    ];
    const handleDegreeChange = (event) => {
        setSelectedDegree(event.target.value);
    };
    const addDegree = () => {
        if (selectedDegree && !degrees.includes(selectedDegree)) {
            setDegrees([...degrees, selectedDegree]);
        } else if (customDegree && !degrees.includes(customDegree)) {
            setDegrees([...degrees, customDegree]);
            setCustomDegree('');
        }
    };
    const handleSpecializationChange = (event) => {
        setSelectedSpecialization(event.target.value);
    };

    const addSpecialization = () => {
        if (selectedSpecialization && !specializations.includes(selectedSpecialization)) {
            setSpecializations([...specializations, selectedSpecialization]);
        }
    };
    const toggleUpdateProfileVisibility = () => {
        setIsUpdateProfileVisible(!isUpdateProfileVisible);
    };
    useEffect(() => {
        if (user) {
            setWorkingLanguages(user.workingLanguages || []);
            setSpecializations(user.specializations || []);
            setDegrees(user.degrees || []);
        }
    }, [user]);

    const handleProfileUpdate = async () => {
        try {
            const updatedUser = {
                ...user,
                workingLanguages,
                specializations,
                degrees,
                isSwornTranslator,
                swornTranslatorDoc,
                identificationDoc,
                presentations,
                photo,
                experiences,
                interests,
                status: 'pending'
            };

            setProfileUpdated(true);
            setUpdatedUser(updatedUser);
            setIsUpdateProfileVisible(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (profileUpdated) {
            const timeout = setTimeout(() => {
                setProfileUpdated(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [profileUpdated]);
    useEffect(() => {
        if (user) {
            setWorkingLanguages(user.workingLanguages || []);
            setSpecializations(user.specializations || []);
            setDegrees(user.degrees || []);
        }
    }, [user]);

    useEffect(() => {
        const fetchTranslatorData = async () => {
            try {
                const response = await axios.get(`https://jetraduis-server.vercel.app/api/translators/forum/${userId}`);
                if (response.status === 200) {
                    const translatorData = response.data;
                    setTranslator(translatorData.user);
                } else {
                    setError('Error fetching translator data');
                }
            } catch (error) {
                setError('Network error while fetching translator data');
            }
        };

        fetchTranslatorData();
    }, [userId]);


    return (
        <div>
            <h1 id="profile-heading">{t('Profil du traducteur')}</h1>
            {(updatedUser || user) ? (<div>
                <p>{t('Bienvenue')} {userId}</p>
                <p>{t('Nom complet')} : {(updatedUser || user)?.fullName}</p>
                <p>{t('Email')} : {(updatedUser || user)?.email}</p>
                <p>{t('Langue native')} : {(updatedUser || user)?.nativeLanguage}</p>
                <p>{t('À propos de moi')} : {(updatedUser || user)?.presentations}</p>
                <img src={(updatedUser || user)?.photo} alt="Profile" className='photoProfil' />
                <p>{t('Experiences')}: {(updatedUser || user)?.experiences}</p>
                <p>{t("Centres d'intérêt")} : {(updatedUser || user)?.interests}</p>
                <p>{t('Langues de travail')} :</p>
                <ul aria-labelledby="working-languages-label">
                    {(updatedUser || user)?.workingLanguages.map((workingLanguages, index) => (
                        <li key={index}>{workingLanguages}</li>
                    ))}
                </ul>
                <p>{t('Spécialités')} :</p>
                <ul aria-labelledby="specializations-label">
                    {(updatedUser || user)?.specializations.map((specialization, index) => (
                        <li key={index}>{specialization}</li>
                    ))}
                </ul>
                <button
                    onClick={toggleUpdateProfileVisibility}
                    className={`hide-update ${isUpdateProfileVisible ? 'active' : ''}`}
                    aria-labelledby="update-profile-label"
                >
                    {isUpdateProfileVisible ? t('Cacher la mise à jour des informations') : t('Mettre à jour les informations')}
                </button>
                {isUpdateProfileVisible && (
                    <div className='update_profile'>
                        <div>
                            <label htmlFor="language-select">{t('Langues de travail')} </label>
                            <select
                                id="language-select"
                                value={selectedLanguage}
                                onChange={handleLanguageChange}
                                aria-live="polite"
                            >
                                {languages.map((language, index) => (
                                    <option key={index} value={ISO6391.getCode(language)}>
                                        {language}
                                    </option>
                                ))}
                            </select>
                            <button onClick={addLanguage} className='add-button' aria-label={t('Ajouter une langue')}>
                                {t('Ajouter')}
                            </button>
                            <div>
                                {t('Langues sélectionnées')} :
                                <ul aria-labelledby="selected-languages-label">
                                    {workingLanguages.map((workingLanguage, index) => (
                                        <li key={index}>
                                            {workingLanguage}
                                            <button onClick={() => removeLanguage(workingLanguage)} aria-label={t('Supprimer')}>
                                                {t('Supprimer')}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <label htmlFor="specialization-select">{t('Specializations')}</label>
                        <select
                            id="specialization-select"
                            value={selectedSpecialization}
                            onChange={handleSpecializationChange}
                            aria-live="polite"
                        >
                            <option value="">{t('Sélectionner une spécialisation')}</option>
                            {SPECIALIZATIONS.map((specialization, index) => (
                                <option key={index} value={specialization}>
                                    {specialization}
                                </option>
                            ))}
                        </select>
                        <button onClick={addSpecialization} className='add-button' aria-label={t('Ajouter une spécialité')}>
                            {t('Ajouter une spécialité')}
                        </button>
                        <div aria-labelledby="selected-specializations-label">
                            {t('Spécialités Selectionnées')} : {specializations.join(', ')}
                        </div>
                        <label htmlFor="degree-select">{t('Degrees')}</label>
                        <select
                            id="degree-select"
                            value={selectedDegree}
                            onChange={handleDegreeChange}
                            aria-live="polite"
                        >
                            <option value="">{t("Choisir un niveau d'études")}</option>
                            {DEGREES_OPTIONS.map((degree, index) => (
                                <option key={index} value={degree}>{degree}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            placeholder="Custom Degree"
                            value={customDegree}
                            onChange={(e) => setCustomDegree(e.target.value)}
                            aria-label={t('Degré personnalisé')}
                        />
                        <button onClick={addDegree} className='add-button' aria-label={t('Ajouter un diplôme')}>
                            {t('Ajouter un diplôme')}
                        </button>
                        <div aria-labelledby="selected-degrees-label">
                            {t('diplômes sélectionnés')} : {degrees.join(', ')}
                        </div>
                        <label>
                            {t('Êtes-vous un traducteur assermenté ')} ?
                            <input
                                type="checkbox"
                                checked={isSwornTranslator}
                                onChange={(e) => setIsSwornTranslator(e.target.checked)}
                            />
                        </label>
                        <input
                            type="file"
                            onChange={(e) => setSwornTranslatorDoc(e.target.files[0])}
                            aria-label={t('Télécharger le document du traducteur assermenté')}
                        />
                        <input
                            type="file"
                            onChange={(e) => setIdentificationDoc(e.target.files[0])}
                            aria-label={t('Télécharger le document d\'identification')}
                        />
                        <label htmlFor="presentations">{t('À propos de moi')}</label>
                        <textarea
                            id="presentations"
                            value={presentations}
                            onChange={handlePresentationsChange}
                            aria-live="polite"
                        />

                        <label htmlFor="photo">{t('Photo')}</label>
                        <input
                            type="text"
                            id="photo"
                            placeholder="Enter Photo URL"
                            value={photo}
                            onChange={handlePhotoChange}
                            aria-live="polite"
                        />

                        <label htmlFor="experiences">{t('Experiences')}</label>
                        <textarea
                            id="experiences"
                            value={experiences}
                            onChange={handleExperiencesChange}
                            aria-live="polite"
                        />

                        <label htmlFor="interests">{t("Centres d'intérêt")}</label>
                        <textarea
                            id="interests"
                            value={interests}
                            onChange={handleInterestsChange}
                            aria-live="polite"
                        />
                        <button onClick={handleProfileUpdate} className='update-button' aria-label={t('Mettre à jour le profil')}>
                            {t('Mettre à jour le profil')}
                        </button>
                    </div>
                )}
            </div>
            ) : null}

            {error ? (
                <p>{error}</p>
            ) : translator ? (
                <div>
                    <h2>{t('Profil du traducteur')}</h2>
                    <p>{t('Bienvenue')} : {translator.fullName}</p>
                    <p>{t('Langue native')} : {translator.nativeLanguage}</p>
                    <p>{t('À propos de moi')} : {translator.presentations}</p>
                    <img src={translator.photo} alt="Profile" className='photoProfil' />
                    <p>{t('Experiences')}: {translator.experiences}</p>
                    <p>{t("Centres d'intérêt")} : {translator.interests}</p>
                    <p>{t('Langues de travail')} :</p>
                    <ul aria-labelledby="translator-working-languages-label">
                        {translator.workingLanguages.map((workingLanguages, index) => (
                            <li key={index}>{workingLanguages}</li>
                        ))}
                    </ul>
                    <p>{t('Spécialités')} :</p>
                    <ul aria-labelledby="translator-specializations-label">
                        {translator.specializations.map((specialization, index) => (
                            <li key={index}>{specialization}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>{t('Chargement')}...</p>
            )}
        </div>
    );

};


export default UserProfile;
