//Services.js
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import './css/Services.css';
import Chat from './Chat/Chat';
import LoginUserModal from '../components/LoginUserModal';
import { LanguageContext } from '../contexts/LanguageContext';

const Services = () => {
  const [translationType, setTranslationType] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('');
  const [numPages, setNumPages] = useState(1);
  const [totalAmount, setTotalAmount] = useState(30);
  const [sendFile, setSendFile] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('');
  const [sourceLanguages, setSourceLanguages] = useState([]);
  const [targetLanguages, setTargetLanguages] = useState([]);
  const [amountTranslator, setamountTranslator] = useState(0);
  const [comment, setComment] = useState(' ');
  const [address, setAddress] = useState(' ');
  const [showSignupModal, setShowSignupModal] = useState(false);
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;
  const [errors, setErrors] = useState({
    translationType: '',
    sourceLanguage: '',
    targetLanguage: '',
    numPages: '',
    deliveryOption: '',
    comment: '',
    address: '',
  });
  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      translationType: '',
      sourceLanguage: '',
      targetLanguage: '',
      numPages: '',
      deliveryOption: '',
      comment: '',
      address: '',
    };

    if (!translationType) {
      newErrors.translationType = 'Veuillez sélectionner un type de traduction.';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  const handleAddFiles = () => {
    setSendFile([...sendFile, '']);
  };
  const handleAddFilesChange = (index, value) => {
    const updatedFiles = [...sendFile];
    updatedFiles[index] = value;
    setSendFile(updatedFiles);
  };

  useEffect(() => {
    axios.get(`https://jetraduis-server.vercel.app/api/translators/source-languages`)
      .then(response => {
        setSourceLanguages(response.data.sourceLanguages);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des langues de source :', error);
      });
    axios.get(`https://jetraduis-server.vercel.app/api/translators/target-languages`)
      .then(response => {
        setTargetLanguages(response.data.targetLanguages);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des langues de destination :', error);
      });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');

    if (type === 'certified' || type === 'general') {
      setTranslationType(type);
    }
  }, [location]);



  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (!user) {
      setShowSignupModal(true);
    } else {
      setShowSignupModal(false);
    }
  }, [user]);


  const handleNumPagesChange = (e) => {
    const action = e.target.name;
    let newNumPages = numPages;
    if (action === 'increment') {
      newNumPages += 1;
    } else if (action === 'decrement' && newNumPages > 0) {
      newNumPages -= 1;
    }
    setNumPages(newNumPages);
    calculateTotalAmount(newNumPages, deliveryOption);
  };

  const handleDeliveryOptionChange = (newDeliveryOption) => {
    setDeliveryOption(newDeliveryOption);
    calculateTotalAmount(numPages, newDeliveryOption);
  };
  const calculateTotalAmount = (pages, option) => {
    let amount = pages * 30;

    if (option === 'postal') {
      amount += 15;
    } else if (option === 'priority') {
      amount += 30;
    } else if (option === 'dhl') {
      amount += 100;
    }
    const amountTranslator = 0.8 * amount;
    setTotalAmount(amount);
    setamountTranslator(amountTranslator);
  };

  const handleAddOrder = async (e) => {
    e.preventDefault();

    const orderNumber = uuidv4();
    const amountTranslator = 0.8 * totalAmount;


    const formData = new FormData();
    formData.append('orderNumber', orderNumber);
    formData.append('user', user ? user._id : null);
    formData.append('translationType', translationType);
    formData.append('sourceLanguage', sourceLanguage);
    formData.append('targetLanguage', targetLanguage);
    formData.append('deliveryOption', deliveryOption);
    formData.append('numPages', numPages);
    formData.append('comment', comment || 'hhghgg');
    formData.append('address', address || 'hghghg');
    sendFile.forEach((file, index) => {
      formData.append('sendFile', file);
    });
    formData.append('totalAmount', totalAmount);
    formData.append('amountTranslator', amountTranslator);

    try {
      const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/cart`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { order } = response.data;
      localStorage.setItem('orderId', order._id);

      navigate(`/ cart ? orderId = ${order._id}`);
    } catch (error) {
      console.error('Error confirming order:', error);
    }
  };


  return (

    <div className='services-container'>
      <Chat />
      <h2>{t('Services')}</h2>
      <form onSubmit={handleAddOrder}>
        <div>
          <h3>{t('Type de traduction')} :</h3>
          <select className='decorated' value={translationType} onChange={(e) => setTranslationType(e.target.value)}>
            <option value="">{t('Sélectionner')} ...</option>
            <option value="certified">{t('Traduction certifiée')}</option>
            <option value="general">{t('Traduction générale')}</option>
            <option value="localization">{t('Traduction certifiée')}</option>
            <option value="interpretation">{t('Traduction générale')}</option>
            <option value="proofreading">{t('Traduction certifiée')}</option>
            <option value="legalization">{t('Traduction générale')}</option>
            <option value="technical">{t('Traduction certifiée')}</option>
            <option value="subtitling">{t('Traduction générale')}</option>
            <option value="contentwriting">{t('Traduction certifiée')}</option>
            <option value="simultaneousinterpretation">{t('Traduction générale')}</option>
            <option value="editing">{t('Traduction certifiée')}</option>
            <option value="languagecourses">{t('Traduction générale')}</option>

          </select>
        </div>
        {errors.translationType && <p className="error-message">{errors.translationType}</p>}
        <div>
          <h3>{t('Sélectionnez la langue de votre document')} :</h3>
          <select value={sourceLanguage} onChange={(e) => setSourceLanguage(e.target.value)}>
            <option value="">{t('Sélectionner')}...</option>
            {sourceLanguages.map(language => (
              <option key={language} value={language}>{language}</option>
            ))}
          </select>
        </div>
        <div>
          <h3>{t('Sélectionnez la langue à laquelle vous traduisez')} :</h3>
          <select value={targetLanguage} onChange={(e) => setTargetLanguage(e.target.value)}>
            <option value="">{t('Sélectionner')}...</option>
            {targetLanguages.map(language => (
              <option key={language} value={language}>{language}</option>
            ))}
          </select>
        </div>
        <div className='number-input-container'>
          <h3>{t('Nombre de pages')} :</h3>
          <div className="button-container">
            <input
              className='number-input'
              type="text"
              name="numPages"
              value={numPages}
              readOnly
            />
            <button type="button" className='number-input-button' name="decrement" onClick={handleNumPagesChange}>-</button>
            <button type="button" className='number-input-button' name="increment" onClick={handleNumPagesChange}>+</button>
          </div>
          <p>{t('Coût par page ')}: 30 € </p>
          <p>{t('Coût total des pages ')}: {numPages * 30} € </p>
        </div>
        <div>
          <h3>{t('Option de livraison')} :</h3>
          <label>
            <input
              type="radio"
              value="email"
              checked={deliveryOption === 'email'}
              onChange={() => handleDeliveryOptionChange('email')}
            />
            {t('Email')} {t('(Gratuit)')}
          </label>
          <label>
            <input
              type="radio"
              value="postal"
              checked={deliveryOption === 'postal'}
              onChange={() => handleDeliveryOptionChange('postal')}
            />
            {t('Livraison postale ')} (15 €)
          </label>
          <label>
            <input
              type="radio"
              value="priority"
              checked={deliveryOption === 'priority'}
              onChange={() => handleDeliveryOptionChange('priority')}
            />
            {t('Livraison postale prioritaire ')} (30 €)
          </label>
          <label>
            <input
              type="radio"
              value="dhl"
              checked={deliveryOption === 'dhl'}
              onChange={() => handleDeliveryOptionChange('dhl')}
            />
            {t('Livraison')} DHL (100 €)
          </label>
        </div>
        <div>
          <h3>{t('Ajouter des fichiers')} :</h3>
          <button onClick={handleAddFiles}> {t('Ajouter un fichier')} </button>
          {sendFile.map((sendFile, index) => (
            <input
              key={index}
              type="file"
              onChange={(e) => handleAddFilesChange(index, e.target.files[0])}
            />
          ))}
        </div>
        <div>
          <h3>Commentaire :</h3>
          <textarea
            rows="4"
            cols="50"
            placeholder="Saisissez votre commentaire"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        <div>
          <h3>Adresse :</h3>
          <textarea
            rows="4"
            cols="50"
            placeholder="Saisissez votre adresse"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></textarea>
        </div>
        <p className="cost-display">{t('Montant total')} : {totalAmount} €</p>
        <button type="submit" disabled={!user}>
          {t('Ajouter au panier')}
        </button>
      </form>
      {showSignupModal && <LoginUserModal />}
      <div className='why-choose-us'>
        <h3>{t('Pourquoi nous choisir')} :</h3>
        <p>
          {t('Notre équipe de traducteurs expérimentés assure des traductions précises et fiables dans divers domaines. Que ce soit pour des documents officiels, des textes techniques ou des contenus créatifs, nous sommes là pour vous aider')} .
        </p>
      </div>
      <div className='translator-collaboration'>
        <h3>{t('Collaboration avec des traducteurs')} :</h3>
        <p>
          {t('Êtes-vous un traducteur professionnel ? Rejoignez notre équipe et collaborez avec nous pour offrir des services de traduction exceptionnels à nos clients')}. <Link to="/signup/translator">{t('Inscrivez-vous ici')} </Link>.
        </p>
      </div>
      <div>
        <h3>{t('Restez connecté ')} :</h3>
        <p>
          {t('Suivez-nous sur les réseaux sociaux pour les dernières mises à jour, promotions et astuces linguistiques. Rejoignez notre communauté en ligne et explorez le monde des langues avec nous')}.
        </p>
        <div>
          <a href="lien_vers_facebook" target="_blank" rel="noopener noreferrer">
            <img src="icône_facebook.png" alt="Facebook" />
          </a>
          <a href="lien_vers_twitter" target="_blank" rel="noopener noreferrer">
            <img src="icône_twitter.png" alt="Twitter" />
          </a>
          <a href="lien_vers_instagram" target="_blank" rel="noopener noreferrer">
            <img src="icône_instagram.png" alt="Instagram" />
          </a>
        </div>
      </div>
      <div>
        <h3>{t('Service client dédié')} :</h3>
        <p>
          {t('Notre équipe de service client est disponible 24h/24 et 7j/7 pour répondre à toutes vos questions. N\'hésitez pas à nous contacter si vous avez besoin d\'aide ou d\'assistance')}.
        </p>
        <p>{t('Numéro de téléphone du service client')} : XXX-XXX-XXXX</p>
        <p>{t('Adresse e-mail du service client')} : support@votreentreprise.com</p>
      </div>
      <div>
        <h3>{t('Inscrivez-vous pour des offres exclusives')} :</h3>
        <p>
          {t('Abonnez-vous à notre newsletter pour recevoir des offres spéciales, des réductions et des conseils linguistiques directement dans votre boîte de réception. Ne manquez aucune opportunité')} !
        </p>
        <form className='form-abonner'>
          <input className='place-abonner' type="email" placeholder={t('Votre adresse e-mail')} />
          <button className='button-abonner' type="submit">{t("S'abonner")}</button>
        </form>
      </div>
    </div>
  );
};

export default Services;

