//Signup.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';

import './css/Login.css'

function Signup() {
  const { user } = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;

  if (user) {
    return (
      <div>
        <h2>{t('Inscription')}</h2>
        <p>{t('Vous êtes déjà connecté en tant que')} :{user.fullName}</p>
      </div>
    );
  }
  return (
    <div>
      <h2>{t('Inscription')}</h2>
      <div className="signup-options">
        <Link to="/client/signup" className="signup-option-card">
          <h3>{t('Client')}</h3>
          <p>{t('Inscription en tant que client')}</p>
        </Link>
        <Link to="/translator/signup" className="signup-option-card">
          <h3>{t('Traducteur')}</h3>
          <p>{t('Inscription en tant que traducteur')}</p>
        </Link>
      </div>
    </div>
  );
}

export default Signup;
