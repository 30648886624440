//LoginTranslator.js
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import './css/Login.css';
import Cookies from 'js-cookie';
import { LanguageContext } from '../contexts/LanguageContext';

const LoginTranslator = () => {
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`https://jetraduis-server.vercel.app/api/translators/signin`, {
        email,
        password,
      }, {
        withCredentials: true,
      });
      const { user } = response.data;
      localStorage.setItem('userId', user._id);
      localStorage.setItem('roleTranslator', user.role);

      setUser(user);
      navigate(`/ translatorDashboard / ${user._id}`);
    } catch (error) {
      if (error.response && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <div className="loginUser" role="region" aria-labelledby="loginTranslatorHeading">
      <h1 id="loginTranslatorHeading">{t('Connexion')}</h1>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="email">{t('Email')}:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">{t('Mot de passe')}:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            autoComplete="current-password"
          />
        </div>
        <div className="buttonSubmit">
          <button className="submit" type="submit">{t('Connectez-vous')}</button>
        </div>
        {errorMessage && (
          <div>
            <div className="error-message">{errorMessage}</div>
            <p>
              {t('Mot de passe oublié')}?{' '}
              <Link to="/recover-password-translator">{t('Récupérer votre mot de passe')}</Link>
            </p>
          </div>
        )}
      </form>
      <p>
        {t("Vous n'avez pas de compte")} ? <Link to="/signup">{t('Inscrivez-vous')}</Link>
      </p>
    </div>
  );
};

export default LoginTranslator;

