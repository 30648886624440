import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LanguageContext } from '../contexts/LanguageContext';
import './css/TranslatorDashboard.css'
const OrderDetailsPage = () => {
    const { orderId: urlOrderId } = useParams();
    const userId = localStorage.getItem('userId');
    const [orderId, setOrderId] = useState(urlOrderId);
    const [orderDetails, setOrderDetails] = useState(null);
    const [proposedOrders, setProposedOrders] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [translatorOrders, setTranslatorOrders] = useState([]);
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [comment, setComment] = useState('');
    const [commentFormVisible, setCommentFormVisible] = useState(false);
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;

    const toggleCommentsVisibility = () => {
        setCommentsVisible(!commentsVisible);
    };
    const toggleCommentForm = (orderId) => {
        setOrderId(orderId);
        setCommentFormVisible(!commentFormVisible);
    };
    const fetchOrderDetails = useCallback(async () => {
        if (!orderId) return;
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.get(`https://jetraduis-server.vercel.app/api/translators/${userId}/orders`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                },
            });
            if (response.status === 200) {
                const specificOrder = response.data.orders.find(order => order._id === orderId);
                if (specificOrder) {
                    setOrderDetails(specificOrder);
                }
            } else {
                throw new Error('Failed to fetch order details');
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    }, [userId, orderId]);
    const fetchProposedOrders = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `https://jetraduis-server.vercel.app/api/translators/${userId}/orders/proposed`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const filteredTrans = response.data.orders.filter(order =>
                order.proposedTranslator.some(pt => pt.translator === userId)
            );
            setProposedOrders(filteredTrans);
        } catch (error) {
            console.error(error);
        }
    };
    const fetchTranslatorOrders = useCallback(async () => {
        try {
            const response = await fetch(`https://jetraduis-server.vercel.app/api/translators/${userId}/orders`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching translator orders');
            }
            const data = await response.json();
            setTranslatorOrders(data.orders);
        } catch (error) {
            console.error(error);
        }
    }, [userId]);
    useEffect(() => {
        fetchTranslatorOrders();
    }, [fetchTranslatorOrders]);
    const handleAcceptOrder = async (orderId) => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/${orderId}/accept`, {
                translatorId: userId,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            fetchProposedOrders();
        } catch (error) {
            console.error('Error accepting order:', error);
        }
    };
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const handleFileUploadComment = async (orderId) => {
        if (selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile, selectedFile.name);
                const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/upload-file/${orderId}/comment`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setSelectedFile(null);
            }
        } else {
            console.error('Aucun fichier sélectionné');
        }
    };

    const handleFileUpload = async (orderId) => {
        if (selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile, selectedFile.name);

                const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/upload-file/${orderId}`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setSelectedFile(null);
            }
        } else {
            console.error('Aucun fichier sélectionné');
        }
    };

    useEffect(() => {
        fetchOrderDetails();
    }, [fetchOrderDetails]);

    const submitComment = async () => {
        try {
            if (!orderId) {
                console.error('Order ID is not defined.');
                return;
            }
            const fileName = selectedFile ? selectedFile.name : 'No File Selected';
            const response = await fetch(`https://jetraduis-server.vercel.app/api/translators/${userId}/orders/${orderId}/comment`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user: userId, text: comment, file: 'fileComment-' + fileName }),
            });
            if (!response.ok) {
                throw new Error(`Error submitting comment for order ${orderId}`);
            }
            setComment('');
            setCommentFormVisible(false);
            fetchTranslatorOrders();
        } catch (error) {
            console.error(error);
        }
    };
    const renderOrderDetails = () => {
        if (!orderDetails) {
            return <p>{t('Chargement des détails de la commande')}...</p>;
        }
        switch (orderDetails.status) {
            case 'proposed':
                return <div><h2>{t('Commandes proposées')}:</h2>
                    <ul>
                        {proposedOrders.map((order) => (
                            <li key={order._id}>
                                <strong>{t('Numéro de commande')} :</strong> {order.orderId}<br />
                                <strong>{t('Langue source')} :</strong> {order.sourceLanguage}<br />
                                <strong>{t('Langue cible')} :</strong> {order.targetLanguage}<br />
                                <strong>{t('Traducteurs proposés')} :</strong>
                                {order.proposedTranslator.map((pt, index) => (
                                    <div key={index}>
                                        {pt.translator}
                                    </div>
                                ))}
                                <button onClick={() => handleAcceptOrder(order._id)}>{t('Accepter la commande')}</button>
                            </li>
                        ))}
                    </ul>
                </div>;

            case 'assigned':
                return (
                    <div>
                        {translatorOrders
                            .filter((order) => order.status === 'assigned')
                            .map((order) => (
                                <div key={order._id}>
                                    <h2>{t('Commandes assignées')} :</h2>
                                    <table className='table-orders'>

                                        <tr>
                                            <th>{t('Numéro de commande')}</th>
                                            <td>{order.orderNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('Langue source')}</th>
                                            <td>{order.sourceLanguage}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('Langue cible')}</th>
                                            <td>{order.targetLanguage}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('Prix')}</th>
                                            <td>{order.amountTranslator} € </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Fichiers à traduire')}</th>
                                            <td>
                                                <a
                                                    href={`https://jetraduis-server.vercel.app/api/translators/${userId}/client-files?orderNumber=${order.orderNumber}`}
                                                    download={`client-files-${userId}.zip`}
                                                >
                                                    {t('Télécharger')}
                                                </a>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            ))}
                        <div>
                            <div>
                                {translatorOrders
                                    .filter((order) => order.status === 'completed' || order.status === 'translated' || order.status === 'cancelled')
                                    .map((order) => (
                                        <div key={order._id}>
                                            <h2>{t('Toutes les commandes')} :</h2>
                                            <table className='table-orders'>
                                                <tr>
                                                    <th>{t('Numéro de commande')}</th>
                                                    <td>{order._id}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('Langue source')}</th>
                                                    <td>{order.sourceLanguage}</td>
                                                </tr>
                                                <tr>
                                                    <th>{t('Langue cible')}</th>
                                                    <td>{order.targetLanguage}</td>
                                                </tr>
                                                <tr><th>{t('Prix')}</th>
                                                    <td>{order.amountTranslator} € </td>
                                                </tr>
                                                <tr>
                                                    <th>{t('Fichiers à traduire')}</th>
                                                    <td>
                                                        <a
                                                            href={`https://jetraduis-server.vercel.app/api/translators/${userId}/client-files?orderNumber=${order.orderNumber}`}
                                                            download={`client-files-${userId}.zip`}
                                                        >
                                                            {t('Télécharger')}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t('Fichier traduit')}</th>
                                                    <td>
                                                        <input type="file" onChange={handleFileChange} />
                                                        <button onClick={() => handleFileUpload(order._id)}>{t('Envoyer')}</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t('Etat')}</th>
                                                    <td>{order.status}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <button
                                    onClick={toggleCommentsVisibility}
                                    className={`button-file-comment ${commentsVisible ? 'active' : ""}`}
                                >
                                    {commentsVisible ? t('Masquer les commentaires') : t('Afficher les commentaires')}
                                </button>

                                {commentsVisible && (
                                    <div className='comments'>
                                        {translatorOrders.map((order, index) => (
                                            <div key={order._id} className="comment-container">
                                                <div className='comment-unity'>
                                                    {order.commentFile && order.commentFile.length > 0 ? (
                                                        <div >
                                                            <h3>{t('Fchier commentaire')} :</h3>
                                                            <ul>
                                                                {order.commentFile.map((file, index) => (
                                                                    <li key={index} className='file-comment'>
                                                                        {decodeURIComponent(file)}
                                                                        <button className='button-file-comment'
                                                                            onClick={() => {
                                                                                window.location.href = `https://jetraduis-server.vercel.app/api/translators/${userId}/comment-files?orderNumber=${order.orderNumber}&fileIndexes=${index}`;
                                                                            }}
                                                                        >
                                                                            {t('Télécharger')}
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ) : (
                                                        <p>Aucun fichier</p>
                                                    )}
                                                </div>
                                                <div>
                                                    <h3>{t('Commentaires')} :</h3>
                                                    <div>
                                                        {order.comment?.filter((comment) => comment.approved)?.map((comment) => (
                                                            <div key={comment._id}>
                                                                {comment.user && <p > <h4 className='user-name-comment'> {comment.fullName} :</h4>{comment.text} </p>}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='add-comment'>
                                                        <h3>{t('Ajouter un commentaire')}</h3>
                                                        <textarea
                                                            rows="4"
                                                            cols="50"
                                                            placeholder={t('Mettez votre commentaire')}
                                                            value={comment}
                                                            onChange={(e) => setComment(e.target.value)}
                                                        ></textarea>
                                                        <button onClick={() => submitComment()} className='button-file-comment'>{t('Soumettre le commentaire')}</button>
                                                    </div>
                                                    <h3>{t('Envoyer les fichiers traduits')}</h3>
                                                    <div className='cellule-file'>
                                                        <input type="file" onChange={handleFileChange} />
                                                        <button onClick={() => handleFileUpload(order._id)} className='button-file-comment'>{t('Envoyer')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div >
                )

            case 'translated':
                return (
                    <div>
                        <table className='table-orders'>
                            {translatorOrders
                                .filter((order) => order.status === 'completed' || order.status === 'translated' || order.status === 'cancelled')
                                .map((order) => (
                                    <div key={order._id}>
                                        <h2>{t('Toutes les commandes')} :</h2>
                                        <tr>
                                            <th>{t('Numéro de commande')}</th>
                                            <td>{order._id}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('Langue source')}</th>
                                            <td>{order.sourceLanguage}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('Langue cible')}</th>
                                            <td>{order.targetLanguage}</td>
                                        </tr>
                                        <tr>
                                            <th>{t('Prix')}</th>
                                            <td>{order.amountTranslator} € </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Fichiers à traduire')}</th>
                                            <td>
                                                <a
                                                    href={`https://jetraduis-server.vercel.app/api/translators/${userId}/client-files?orderNumber=${order.orderNumber}`}
                                                    download={`client-files-${userId}.zip`}
                                                >
                                                    {t('Télécharger')}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Fichier traduit')}</th>
                                            <td>
                                                <input type="file" onChange={handleFileChange} />
                                                <button onClick={() => handleFileUpload(order._id)}>{t('Envoyer')}</button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('Etat')}</th>
                                            <td>{order.status}</td>
                                        </tr>
                                    </div>
                                ))}
                        </table>
                        <div>
                            <button onClick={toggleCommentsVisibility} className='button-file-comment'>
                                {commentsVisible ? t('Masquer les commentaires') : t('Afficher les commentaires')}
                            </button>
                            {commentsVisible && (
                                <div className='comments'>
                                    {translatorOrders.map((order, index) => (
                                        <div key={order._id} className="comment-container">
                                            <div className='comment-unity'>
                                                {order.commentFile && order.commentFile.length > 0 ? (
                                                    <div >
                                                        <h3>{t('Fchier commentaire')} :</h3>
                                                        <ul>
                                                            {order.commentFile.map((file, index) => (
                                                                <li key={index} className='file-comment'>
                                                                    {decodeURIComponent(file)}
                                                                    <button className='button-file-comment'
                                                                        onClick={() => {
                                                                            window.location.href = `https://jetraduis-server.vercel.app/api/translators/${userId}/comment-files?orderNumber=${order.orderNumber}&fileIndexes=${index}`;
                                                                        }}
                                                                    >
                                                                        {t('Télécharger')}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : (
                                                    <p>Aucun fichier</p>
                                                )}
                                            </div>
                                            <div>
                                                <h3>{t('Commentaires')} :</h3>
                                                <div>
                                                    {order.comment?.filter((comment) => comment.approved)?.map((comment) => (
                                                        <div key={comment._id}>
                                                            {comment.user && <p > <h4 className='user-name-comment'> {comment.fullName} :</h4>{comment.text} </p>}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div>
                                                <div className='add-comment'>
                                                    <h3>{t('Ajouter un commentaire')}</h3>
                                                    <textarea
                                                        rows="4"
                                                        cols="50"
                                                        placeholder={t('Mettez votre commentaire')}
                                                        value={comment}
                                                        onChange={(e) => setComment(e.target.value)}
                                                    ></textarea>
                                                    <button onClick={() => submitComment()} className='button-file-comment'>{t('Soumettre le commentaire')}</button>
                                                </div>
                                                <h3>{t('Envoyer les fichiers traduits')}</h3>
                                                <div className='cellule-file'>
                                                    <input type="file" onChange={handleFileChange} />
                                                    <button onClick={() => handleFileUpload(order._id)} className='button-file-comment'>{t('Envoyer')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </div>
                            )}
                        </div>
                    </div >
                );
            default:
                return <p>{t('Etat inconnu')}</p>;
        }
    };

    return (
        <div role="region" aria-labelledby="orderDetailsHeading">
            <h1 id="orderDetailsHeading">{t('Détails de la commande')}</h1>
            {renderOrderDetails()}
        </div>
    );

};

export default OrderDetailsPage;
