//SignupUser.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';

const SignupUserModal = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { setUser } = useContext(AuthContext); // Get the setUser function from AuthContext

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://jetraduis-server.vercel.app/api/users/signup`, {
                fullName,
                email,
                password,
            });
            // If login is successful, get the JWT token and user data from the response
            const { token, user } = response.data;

            // Store the JWT token securely (e.g., in localStorage or cookies)
            localStorage.setItem('token', token);
            localStorage.setItem('userId', user._id); // Store the user ID

            // Update the AuthContext with the user data
            setUser(user);

            // Redirect the user to their dashboard with the user ID
        } catch (error) {
            console.error('Error:', error);
        }
    };




    return (
        <div className='modalSignup'>
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div className='buttonSubmit'>
                    <button className='submit' type="submit">Sign Up</button>
                </div>

            </form>
        </div>
    );
};

export default SignupUserModal;
