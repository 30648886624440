//SignupUser.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { LanguageContext } from '../contexts/LanguageContext';
import SignupUserModal from '../components/SignupUserModal';

const LoginUserModal = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { setUser } = useContext(AuthContext);
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;




    //Drop Menu
    const dropDown = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    };
    window.onclick = function (event) {
        const isDropdownButton = event.target.matches('.dropbtn');
        const isInputField = event.target.tagName === 'INPUT';
        if (!isDropdownButton && !isInputField) {
            let dropdowns = document.getElementsByClassName("dropdown-content");
            let i;
            for (i = 0; i < dropdowns.length; i++) {
                let openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) { }
            }
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://jetraduis-server.vercel.app/api/users/signin`, {
                email,
                password,
            }, {
                withCredentials: true
            });
            const { user } = response.data;
            localStorage.setItem('userId', user._id);
            localStorage.setItem('roleUser', user.role);

            setUser(user);
        } catch (error) {
            if (error?.response?.data?.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Something went wrong. Please try again.');
            }
        }
    };




    return (
        <div className='modalSignup'>
            <h2>Vous avez déjà un compte ? connectez-vous</h2>
            <form onSubmit={handleLogin}>
                <div>
                    <label>{t('Email')} :</label>
                    <input type="email" value={email} onChange={handleEmailChange} required />
                </div>
                <div>
                    <label>{t('Mot de passe')}</label>
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        autoComplete="current-password"
                    />
                </div>
                <div className='buttonSubmit'>
                    <button className='submit' type="submit">{t('Connectez-vous')}</button>
                </div>
                {errorMessage && <div> <div className="error-message">{errorMessage}</div>
                    <p>
                        {t('Mot de passe oublié')}? <Link to="/recover-password-translator"> {t('Récupérer votre mot de passe')}</Link>
                    </p>
                </div>}
            </form>

            <div className="dropdown">
                <button onClick={dropDown} className="dropbtn">{t("Vous n'avez pas de compte")} ? {t("Inscrivez-vous")}</button>
                <div id="myDropdown" className="dropdown-content">
                    {<SignupUserModal />}
                </div>
            </div>




        </div>
    );
};

export default LoginUserModal;
