import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const adminToken = localStorage.getItem('adminToken');
    const adminId = localStorage.getItem('adminId');
    const roleUser = localStorage.getItem('roleUser');
    const roleTranslator = localStorage.getItem('roleTranslator');

    if (userId) {
      if (roleUser) {
        verifyUserSession();
      } else if (roleTranslator) {
        verifyTranslatorSession();
      }
    } else if (adminToken && adminId) {
      fetchAdminData(adminToken, adminId);
    } else {
      setLoading(false);
      setError('Authentication token or user ID not found');
    }
  }, []);

  const verifyUserSession = async () => {
    try {
      const response = await fetch(`https://jetraduis-server.vercel.app/api/users/checkSession`, {
        credentials: 'include',
      });
      const data = await response.json();

      if (data.isAuthenticated) {
        if (data.user.role === 'admin') {
          setAdmin(data.user);
        } else {
          setUser(data.user);
        }
      } else {
        setError('Non authentifié');

      }
    } catch (error) {
      console.error('Error:', error);

      setError(error.message);
    } finally {
      setLoading(false);

    }
  };

  const verifyTranslatorSession = async () => {
    try {
      const response = await fetch(`https://jetraduis-server.vercel.app/api/translators/checkSession`, {
        credentials: 'include',
      });
      const data = await response.json();
      if (data.isAuthenticated) {
        if (data.user.role === 'admin') {
          setAdmin(data.user);
        } else {
          setUser(data.user);
        }
      } else {
        setError('Non authentifié');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdminData = (adminToken, adminId) => {
    fetch(`https://jetraduis-server.vercel.app/api/admin`, {
      headers: { Authorization: `Bearer ${adminToken}` },
    })
      .then(response => response.json())
      .then(data => {
        const admin = data.admin.find(a => a._id === adminId);
        if (admin) {
          setAdmin(admin);
        } else {
          throw new Error('User not found');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setError(error.message);
      });
  };

  const logoutUser = async () => {
    try {
      await fetch(`https://jetraduis-server.vercel.app/api/users/logout`, {
        method: 'POST',
        credentials: 'include',
      });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setUser(null);
      setAdmin(null);
      setError(null);
      localStorage.clear();
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, admin, setAdmin, error, logoutUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
