import React, { useContext, useEffect } from 'react';
import { LoaderContext } from './contexts/LoaderContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import LoginTranslator from './pages/LoginTranslator';
import LoginUser from './pages/LoginUser';
import SignupTranslator from './pages/SignupTranslator';
import SignupUser from './pages/SignupUser';
import UserDashboard from './pages/UserDashboard';
import TranslatorDashboard from './pages/TranslatorDashboard';
import Cart from './pages/Cart';
import OrderConfirmation from './pages/OrderConfirmation';
import Services from './pages/Services';
import Contact from './pages/Contact';
import About from './pages/About';
import { AuthProvider } from './contexts/AuthContext';
import LoginAdmin from './pages/LoginAdmin';
import SignupAdmin from './pages/SignupAdmin';
import AdminDashboard from './pages/AdminDashboard';
import RecoverPasswordTranslator from './pages/RecoverPasswordTranslator';
import RecoverPasswordUser from './pages/RecoverPasswordUser';
import ResetPasswordTranslator from './pages/ResetPasswordTranslator';
import ResetPasswordUser from './pages/ResetPasswordUser';
import AcceptOrderComponent from './pages/AcceptOrderComponent'
import OrderDetailsPage from './pages/OrderDetailsPage';
import TranslatorsList from './pages/TranslatorsList';
import ServicesBannieres from './pages/AllServices';
import ServicesCertified from './services/ServicesCertified';
import ServicesContentwriting from './services/ServicesContentwriting';
import ServicesEditing from './services/ServicesEditing';
import ServicesGeneral from './services/ServicesGeneral';
import ServicesInterpretation from './services/ServicesInterpretation';
import ServicesLanguageCourses from './services/ServicesLanguageCourses';
import ServicesLegalization from './services/ServicesLegalization';
import ServicesLocalization from './services/ServicesLocalization';
import ServicesProofreading from './services/ServicesProofreading';
import ServicesSimultanious from './services/ServicesSimultanious';
import ServicesSubtitling from './services/ServicesSubtitling';
import ServicesTechnical from './services/ServicesTechnical';
import Chat from './pages/Chat/Chat'; // Adjust the path
import { RoomProvider } from './pages/Chat/RoomContext';

import './App.css';
//forum
import DiscussionList from './pages/Forum/ForumDiscussion/DiscussionList';
import Discussion from './pages/Forum/ForumDiscussion/Discussion';
import UserProfile from './pages/Forum/UserProfile/UserProfile';
import UserProfileUpdate from './pages/Forum/UserProfile/UserProfileUpdate';
//Stripe
import RefundConfirmation from './pages/RefundConfirmation'

function App() {
  const { setLoading } = useContext(LoaderContext);

  useEffect(() => {
    window.onload = () => {
      setLoading(false);
    };
  }, [setLoading]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [setLoading]);

  return (

    <LanguageProvider>
      <AuthProvider>
        <Router>
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/translator/login" element={<LoginTranslator />} />
            <Route path="/user/login" element={<LoginUser />} />
            <Route path="/translator/signup" element={<SignupTranslator />} />
            <Route path="/client/signup" element={<SignupUser />} />
            <Route path="/userDashboard/:userId" element={<UserDashboard />} />
            <Route path="/translatorDashboard/:userId" element={<TranslatorDashboard />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/confirmation" element={<OrderConfirmation />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/admin/login" element={<LoginAdmin />} />
            <Route path="/admin/signup" element={<SignupAdmin />} />
            <Route path="/admin/dashboard/:adminId" element={<AdminDashboard />} />
            <Route path="/recover-password-translator" element={<RecoverPasswordTranslator />} />
            <Route path="/recover-password-user" element={<RecoverPasswordUser />} />
            <Route path="/reset-password-translator" element={<ResetPasswordTranslator />} />
            <Route path="/reset-password-user" element={<ResetPasswordUser />} />
            <Route path="/accept-order" element={<AcceptOrderComponent />} />
            <Route path="/order/details/:orderId" element={<OrderDetailsPage />} />
            <Route path="/admin/translatorsList" element={<TranslatorsList />} />
            <Route path="/forum/discussions/:id" element={<Discussion />} />
            <Route path="/forum/user/:id" element={<UserProfile />} />
            <Route path="/forum/translator/:id" element={<UserProfileUpdate />} />
            <Route path="/forum" element={<DiscussionList />} />
            <Route path="/refund-confirmation" element={<RefundConfirmation />} />
            <Route path="/all-services" element={<ServicesBannieres />} />
            <Route path="/services-certified" element={<ServicesCertified />} />
            <Route path="/services-contentwriting" element={<ServicesContentwriting />} />
            <Route path="/services-editing" element={<ServicesEditing />} />
            <Route path="/services-general" element={<ServicesGeneral />} />
            <Route path="/services-interpretation" element={<ServicesInterpretation />} />
            <Route path="/services-languages" element={<ServicesLanguageCourses />} />
            <Route path="/services-legalization" element={<ServicesLegalization />} />
            <Route path="/services-localization" element={<ServicesLocalization />} />
            <Route path="/services-proofreading" element={<ServicesProofreading />} />
            <Route path="/services-simultanious" element={<ServicesSimultanious />} />
            <Route path="/services-subtitling" element={<ServicesSubtitling />} />
            <Route path="/services-technical" element={<ServicesTechnical />} />


          </Routes>
          <RoomProvider>
            <Chat />
          </RoomProvider>
          <Footer />
        </Router>
      </AuthProvider>
    </LanguageProvider>

  );
}

export default App;
