import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

const ChatAdmin = () => {
    const [messages, setMessages] = useState([]);
    const [showChat, setShowChat] = useState(true);
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const [newMessageCount, setNewMessageCount] = useState(0);
    const [roomMessages, setRoomMessages] = useState({});

    const loadChatMessages = async () => {
        try {
            const response = await fetch(`https://jetraduis-server.vercel.app/api/chat/get_messages`);
            const data = await response.json();
            setMessages(data.messages);

        } catch (error) {
            console.error('Error loading chat messages:', error);
        }
    };

    useEffect(() => {
        if (showChat) {
            loadChatMessages();
        }
    }, [showChat]);

    const adminMessages = messages.filter(message => message.author === 'ADMIN');
    const userMessages = messages.filter(message => message.author !== 'ADMIN');

    const groupedMessages = {};
    [...adminMessages, ...userMessages].forEach((message) => {
        const roomId = message.roomId;
        if (!groupedMessages[roomId]) {
            groupedMessages[roomId] = [];
        }
        groupedMessages[roomId].push(message);
    });

    Object.keys(groupedMessages).forEach((roomId) => {
        groupedMessages[roomId].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    });

    const sendMessage = async (roomId) => {
        try {
            if (roomId) {
                await fetch(`https://jetraduis-server.vercel.app/api/chat/add_admin_message`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ text: roomMessages[roomId], roomId }),
                });

                loadChatMessages();
                setRoomMessages({ ...roomMessages, [roomId]: '' });
                setNewMessageCount(newMessageCount + 1);

            } else {
                console.error('Target room not selected.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };
    useEffect(() => {
        loadChatMessages();
    });

    useEffect(() => {
        loadChatMessages();
    }, [newMessageCount]);
    return (
        <div className='chat-div'>
            {Object.keys(groupedMessages).map((roomId) => (
                <div className="chat-container">
                    <h3 className='chat-title'>Room ID: {roomId}</h3>
                    <div className="chat-window">
                        {groupedMessages[roomId].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)).map((message) => (
                            <div key={message._id} className="message-chat">
                                <p><span className='chat-author'>{message.author} </span>: {message.text}</p>
                            </div>
                        ))}
                    </div>
                    <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }} className="chat-input">
                        <input
                            className="input-block"
                            type="text"
                            placeholder="Type your message..."
                            value={roomMessages[roomId] || ''}
                            onChange={(e) => setRoomMessages({ ...roomMessages, [roomId]: e.target.value })}
                        />

                        <button onClick={() => { sendMessage(roomId); }} > <FontAwesomeIcon icon={faPaperPlane} /></button>
                    </form>
                </div>
            ))}
            {newMessageCount > 0 && (
                <div className="notification">
                    {newMessageCount === 1 ? (
                        <p>You have a new message!</p>
                    ) : (
                        <p>You have {newMessageCount} new messages!</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatAdmin;
