import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import ISO6391 from 'iso-639-1';
import './UserProfil.css';


const UserProfile = () => {
    const { id: userId } = useParams();
    const { user } = useContext(AuthContext);
    const [translator, setTranslator] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [error, setError] = useState(null);
    const [workingLanguages, setWorkingLanguages] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [degrees, setDegrees] = useState([]);
    const [isSwornTranslator, setIsSwornTranslator] = useState(false);
    const [swornTranslatorDoc, setSwornTranslatorDoc] = useState(null);
    const [identificationDoc, setIdentificationDoc] = useState(null);
    const [isUpdateProfileVisible, setIsUpdateProfileVisible] = useState(false);
    const [selectedSpecialization, setSelectedSpecialization] = useState('');
    const [selectedDegree, setSelectedDegree] = useState('');
    const [customDegree, setCustomDegree] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [presentations, setPresentations] = useState('');
    const [photo, setPhoto] = useState('');
    const [experiences, setExperiences] = useState(['']);
    const [interests, setInterests] = useState('');
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [updatedUser, setUpdatedUser] = useState(null);

    const languages = ISO6391.getAllNames();

    const handlePresentationsChange = (event) => {
        setPresentations(event.target.value);
    };

    const handlePhotoChange = (event) => {
        setPhoto(event.target.value);
    };

    const handleExperiencesChange = (event) => {
        setExperiences(event.target.value);
    };

    const handleInterestsChange = (event) => {
        setInterests(event.target.value);
    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };
    const addLanguage = () => {
        const languageName = ISO6391.getName(selectedLanguage);
        if (languageName && !workingLanguages.includes(languageName)) {
            setWorkingLanguages([...workingLanguages, languageName]);
        }
    };

    const removeLanguage = (languageNameToRemove) => {
        const updatedLanguages = workingLanguages.filter(lang => lang !== languageNameToRemove);
        setWorkingLanguages(updatedLanguages);
    };


    const DEGREES_OPTIONS = [
        "Postgraduate Diploma",
        "Bachelor",
        "Master",
        "Doctorate"
    ];
    const SPECIALIZATIONS = [
        "Technique",
        "Médicale et Pharmaceutique",
        "Juridique et Légale",
        "Financière et Économique",
        "Contenus Web Marketing",
        "Localisation de Logiciels et de Jeux Vidéo",
        "Littéraire",
        "Audiovisuelle",
        "Scientifique",
        "Documents Officiels et Certifiés",
    ];

    const handleDegreeChange = (event) => {
        setSelectedDegree(event.target.value);
    };

    const addDegree = () => {
        if (selectedDegree && !degrees.includes(selectedDegree)) {
            setDegrees([...degrees, selectedDegree]);
        } else if (customDegree && !degrees.includes(customDegree)) {
            setDegrees([...degrees, customDegree]);
            setCustomDegree('');
        }
    };
    const handleSpecializationChange = (event) => {
        setSelectedSpecialization(event.target.value);
    };
    const addSpecialization = () => {
        if (selectedSpecialization && !specializations.includes(selectedSpecialization)) {
            setSpecializations([...specializations, selectedSpecialization]);
        }
    };
    const toggleUpdateProfileVisibility = () => {
        setIsUpdateProfileVisible(!isUpdateProfileVisible);
    };
    useEffect(() => {
        if (user) {
            setWorkingLanguages(user.workingLanguages || []);
            setSpecializations(user.specializations || []);
            setDegrees(user.degrees || []);
        }
    }, [user]);
    const handleProfileUpdate = async () => {
        try {
            const updatedUser = {
                ...user,
                workingLanguages,
                specializations,
                degrees,
                isSwornTranslator,
                swornTranslatorDoc,
                identificationDoc,
                presentations,
                photo,
                experiences,
                interests,
                status: 'pending'
            };

            setProfileUpdated(true);
            setUpdatedUser(updatedUser);
            setIsUpdateProfileVisible(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (profileUpdated) {
            const timeout = setTimeout(() => {
                setProfileUpdated(false);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [profileUpdated]);
    useEffect(() => {
        if (user) {
            setWorkingLanguages(user.workingLanguages || []);
            setSpecializations(user.specializations || []);
            setDegrees(user.degrees || []);
        }
    }, [user]);

    useEffect(() => {
        const fetchTranslatorData = async () => {
            try {
                const response = await axios.get(`https://jetraduis-server.vercel.app/api/translators/forum/${userId}`);
                if (response.status === 200) {
                    const translatorData = response.data;
                    setTranslator(translatorData.user);
                } else {
                    setError('Error fetching translator data');
                }
            } catch (error) {
                setError('Network error while fetching translator data');
            }
        };

        fetchTranslatorData();
    }, [userId]);

    return (
        <div className="container">
            <h1 className="user-profile-heading">Translator Profil</h1>
            {(updatedUser || user) ? (<div className="user-profile-details">
                <div className='title_photo'><p>Welcome, User ID: {userId}</p>
                    <img src={(updatedUser || user)?.photo} alt="Profile" className='photoProfil' />
                </div>
                <p>Full Name: {(updatedUser || user)?.fullName}</p>
                <p>Email: {(updatedUser || user)?.email}</p>
                <p>Native Language: {(updatedUser || user)?.nativeLanguage}</p>
                <p>About Me: {(updatedUser || user)?.presentations}</p>
                <p>Experiences: {(updatedUser || user)?.experiences}</p>
                <p>Interests: {(updatedUser || user)?.interests}</p>
                <p>Working Languages:</p>
                <ul>
                    {(updatedUser || user)?.workingLanguages.map((workingLanguages, index) => (
                        <li key={index}>{workingLanguages}</li>
                    ))}
                </ul>
                <p>Specializations:</p>
                <ul>
                    {(updatedUser || user)?.specializations.map((specialization, index) => (
                        <li key={index}>{specialization}</li>
                    ))}
                </ul>
                <button
                    onClick={toggleUpdateProfileVisibility}
                    className={`hide-update ${isUpdateProfileVisible ? 'active' : ''}`}
                >
                    {isUpdateProfileVisible ? 'Hide Update Information' : 'Update Information'}
                </button>
                {isUpdateProfileVisible && (
                    <div className='update_profile'>
                        <div>
                            <label>Working Languages</label>
                            <select value={selectedLanguage} onChange={handleLanguageChange}>
                                {languages.map((language, index) => (
                                    <option key={index} value={ISO6391.getCode(language)}>
                                        {language}
                                    </option>
                                ))}
                            </select>
                            <button onClick={addLanguage} className='add-button'>Add Language</button>
                            <div>
                                Selected Languages:
                                <ul>
                                    {workingLanguages.map((workingLanguage, index) => (
                                        <li key={index}>
                                            {workingLanguage}
                                            <button onClick={() => removeLanguage(workingLanguage)}>Supprimer</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <label>Specializations</label>
                        <select value={selectedSpecialization} onChange={handleSpecializationChange}>
                            <option value="">Select a Specialization</option>
                            {SPECIALIZATIONS.map((specialization, index) => (
                                <option key={index} value={specialization}>
                                    {specialization}
                                </option>
                            ))}
                        </select>
                        <button onClick={addSpecialization} className='add-button'>Add Specialization</button>
                        <div>Selected Specializations: {specializations.join(', ')}</div>
                        <label>Degrees</label>
                        <select value={selectedDegree} onChange={handleDegreeChange}>
                            <option value="">Select a Degree</option>
                            {DEGREES_OPTIONS.map((degree, index) => (
                                <option key={index} value={degree}>{degree}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            placeholder="Custom Degree"
                            value={customDegree}
                            onChange={(e) => setCustomDegree(e.target.value)}
                        />
                        <button onClick={addDegree} className='add-button'>Add Degree</button>
                        <div>Selected Degrees: {degrees.join(', ')}</div>
                        <label>
                            Are you a sworn translator?
                            <input
                                type="checkbox"
                                checked={isSwornTranslator}
                                onChange={(e) => setIsSwornTranslator(e.target.checked)}
                            />
                        </label>
                        <input
                            type="file"
                            onChange={(e) => setSwornTranslatorDoc(e.target.files[0])}
                        />
                        <input
                            type="file"
                            onChange={(e) => setIdentificationDoc(e.target.files[0])}
                        />
                        <label>About Me</label>
                        <textarea
                            value={presentations}
                            onChange={handlePresentationsChange}
                        />
                        <label>Photo URL</label>
                        <input
                            type="text"
                            placeholder="Enter Photo URL"
                            value={photo}
                            onChange={handlePhotoChange}
                        />
                        <label>Experiences</label>
                        <textarea
                            value={experiences}
                            onChange={handleExperiencesChange}
                        />
                        <label>Interests</label>
                        <textarea
                            value={interests}
                            onChange={handleInterestsChange}
                        />
                        <button onClick={handleProfileUpdate} className='update-button'>Update Profile</button>
                    </div>
                )}
            </div>
            ) : null}
        </div>
    );
};

export default UserProfile;
