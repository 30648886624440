
// Chat.jsx
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { RoomContext } from './RoomContext';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import './Chat.css';
const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const { user, token } = useContext(AuthContext);
    const { roomId: contextRoomId, updateRoomId } = useContext(RoomContext);
    const [showChat, setShowChat] = useState(false);
    const [notification, setNotification] = useState(null);
    const [messageCount, setMessageCount] = useState(0);
    const [lastMessageCount, setLastMessageCount] = useState(0);

    const generateUniqueRoomId = () => uuidv4();



    useEffect(() => {
        const storedRoomId = getCookie('chatRoomId');

        if (storedRoomId) {
            updateRoomId(storedRoomId);
        } else {
            const newRoomId = generateUniqueRoomId();
            updateRoomId(newRoomId);
            document.cookie = `chatRoomId=${newRoomId}; path=/`;
        }
    }, [updateRoomId]);

    useEffect(() => {
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                console.log('Notification permission:', permission);
            });
        }
    }, []);

    const showNotification = async () => {
        if (Notification.permission === 'granted') {
            const lastMessage = messages[messages.length - 1];

            if (lastMessage) {
                return new Promise((resolve, reject) => {
                    const notification = new Notification(`${lastMessage.author} says:`, {
                        body: lastMessage.text,
                    });

                    notification.onshow = () => resolve(notification);
                    notification.onerror = (error) => reject(error);
                });
            }
        }
        return null;
    };

    const fetchMessages = async () => {
        try {
            const response = await axios.get(`https://jetraduis-server.vercel.app/api/chat/get_messages`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    roomId: contextRoomId,
                },
            });

            const newMessages = response.data.messages;
            setMessages(newMessages);

        } catch (error) {
            console.error('Error fetching chat messages:', error);
        }
    };

    const addMessage = async () => {
        try {
            let author = user?.fullName || `visitor${contextRoomId ? contextRoomId.slice(-5) : '00000'}`;

            const currentRoomId = contextRoomId || generateUniqueRoomId();

            const response = await axios.post(
                `https://jetraduis-server.vercel.app/api/chat/add_messages`,
                { text: newMessage, userId: user?._id, token, fullName: author, roomId: currentRoomId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const { token: newToken } = response.data;
            localStorage.setItem('token', newToken);

            setNewMessage('');
            updateRoomId(currentRoomId);
            fetchMessages();
            setMessageCount((prevCount) => prevCount + 1);

        } catch (error) {
            console.error('Error adding chat message:', error);
        }
    };

    useEffect(() => {
        let notificationInstance = null;

        const updateMessagesAndShowNotification = async () => {
            const newMessages = await fetchMessages();

            if (newMessages.length > 0) {
                const latestMessage = newMessages[newMessages.length - 1];
                const notification = await showNotification(latestMessage);

                if (notification) {
                    notificationInstance = notification;
                    setNotification(notification);
                }
            }
        };


        const fetchData = async () => {
            await fetchMessages();
            updateMessagesAndShowNotification();
        };

        if (token && showChat) {
            fetchData();
        }

        return () => {
            if (notificationInstance) {
                notificationInstance.close();
            }
        };
    });


    useEffect(() => { fetchMessages() }, [messages]);

    useEffect(() => {
        return () => {
            if (notification) {
                notification.close();
            }
        };
    }, [notification]);

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const handleScrollUp = () => {
        const chatWindow = document.querySelector('.chat-window');
        if (chatWindow) {
            chatWindow.scrollTop = 0;
        }
    };
    return (
        <div className='chat-div'>
            {showChat && (
                <div className="chat-container">
                    <h3 className='chat-title'>Messagerie</h3>
                    <div className="chat-window">
                        {messages.map((message) => (
                            message.roomId === contextRoomId && (
                                <div key={message._id} className="message-chat">
                                    <p><span className='chat-author'>{message.author} </span>: {message.text}</p>
                                </div>
                            )
                        ))}
                    </div>
                    <form onSubmit={(e) => { e.preventDefault(); addMessage(); }} className="chat-input">
                        <input
                            className="input-block"
                            type="text"
                            placeholder="Type your message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <button type="submit" className='send-button'>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </form>

                    <button className="scroll-up" onClick={handleScrollUp}>
                        &#8593;
                    </button>
                </div>
            )}
            <button onClick={() => setShowChat((prevShowChat) => !prevShowChat)} className='chat-button'>Question ?</button>

        </div>
    );
};

export default Chat;
