//SignupTranslator.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import ISO6391 from 'iso-639-1';
import { getNames } from 'country-list';
import { LanguageContext } from '../contexts/LanguageContext';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const SignupTranslator = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const { setUser } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nativeLanguage, setNativeLanguage] = useState('');
  const [workingLanguages, setWorkingLanguages] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [isSwornTranslator, setIsSwornTranslator] = useState(false);
  const [swornTranslatorDoc, setSwornTranslatorDoc] = useState(null);
  const [identificationDoc, setIdentificationDoc] = useState(null);
  const [identificationDocs, setIdentificationDocs] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState('');
  const [selectedDegree, setSelectedDegree] = useState('');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const navigate = useNavigate();

  const countryOptions = getNames();
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;
  const languageOptions = ISO6391.getAllNames();
  const SPECIALIZATIONS = [
    "Technique",
    "Médicale et Pharmaceutique",
    "Juridique et Légale",
    "Financière et Économique",
    "Contenus Web Marketing",
    "Localisation de Logiciels et de Jeux Vidéo",
    "Littéraire",
    "Audiovisuelle",
    "Scientifique",
    "Documents Officiels et Certifiés",
  ];
  const DEGREES = [
    "Postgraduate Diploma",
    "Bachelor ",
    "Master ",
    "Doctorate ",
  ];
  const handleAddSpecialization = () => {
    if (selectedSpecialization && !specializations.includes(selectedSpecialization)) {
      setSpecializations([...specializations, selectedSpecialization]);
    }
  };

  const handleAddDegree = () => {
    if (selectedDegree && !degrees.includes(selectedDegree)) {
      setDegrees([...degrees, selectedDegree]);
    }
  };

  const handleNativeLanguageChange = (event) => {
    setNativeLanguage(event.target.value);
  };


  const handleWorkingLanguageChange = (index, value) => {
    const updatedLanguages = [...workingLanguages];
    updatedLanguages[index] = value;
    setWorkingLanguages(updatedLanguages);
  };


  const handleAddWorkingLanguage = () => {
    setWorkingLanguages([...workingLanguages, '']);
  };


  const handleAddIdentificationDoc = () => {
    setIdentificationDocs([...identificationDocs, null]);
  };
  const handleIdentificationDocChange = (index, file) => {
    const updatedDocs = [...identificationDocs];
    updatedDocs[index] = file;
    setIdentificationDocs(updatedDocs);
  };

  const handleLanguageChange = (index, value) => {
    const updatedLanguages = [...workingLanguages];
    updatedLanguages[index] = value;
    setWorkingLanguages(updatedLanguages);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('fullName', fullName);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('address', address);
    formData.append('phoneNumber', phoneNumber);
    formData.append('countryOfResidence', countryOfResidence);
    formData.append('nativeLanguage', nativeLanguage);

    workingLanguages.forEach((language, index) => {
      formData.append(`workingLanguages[${index}]`, language);
    });

    specializations.forEach(specialization => {
      formData.append('specializations[]', specialization);
    });

    degrees.forEach(degree => {
      formData.append('degrees[]', degree);
    });

    formData.append('isSwornTranslator', isSwornTranslator);
    formData.append('swornTranslatorDoc', swornTranslatorDoc);
    formData.append('identificationDoc', identificationDoc);
    identificationDocs.forEach((file, index) => {
      formData.append(`identificationDocs[${index}]`, file);
    });

    try {
      const response = await axios.post(`https://jetraduis-server.vercel.app/api/translators/signup`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const { token, translator } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('userId', translator._id);
      setUser(translator);
      // Mise à jour réussie de l'inscription
      setRegistrationSuccess(true);
      navigate(`/ translatorDashboard / ${translator._id}`);

    } catch (error) {
      console.error(error.response.data);
    }
  };

  return (
    <div className='loginUser'>
      {registrationSuccess ? (
        <div>
          <h2>{t('Inscription réussie !')}</h2>
        </div>
      ) : (
        <div>
          <h2>{t('Inscription traducteur')}</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder={t('Nom complet')}
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <input
              type="email"
              placeholder={t('Email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder={t('Mot de passe')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="text"
              placeholder={t('Adresse')}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              type="text"
              placeholder={t('Numéro de téléphone')}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <div>
              <label>{t('Pays de résidence')}</label>
              <select value={countryOfResidence} onChange={(e) => setCountryOfResidence(e.target.value)}>
                <option value="">{t('Sélectionnez un pays')}</option>
                {countryOptions.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
            </div>
            <div>{t('Langue native')}
              <div>
                <select value={nativeLanguage} onChange={handleNativeLanguageChange}>
                  <option value="">{t('Sélectionnez votre langue native')}</option>
                  {languageOptions.map((language, index) => (
                    <option key={index} value={language}>{language}</option>
                  ))}
                </select>
              </div>
            </div>


            <div>{t('Langues de travail')}
              <div>
                {workingLanguages.map((language, index) => (
                  <select key={index} value={language} onChange={(e) => handleWorkingLanguageChange(index, e.target.value)}>
                    <option value="">{t('Sélectionnez vos langues de travail')}</option>
                    {languageOptions.map((lang, idx) => (
                      <option key={idx} value={lang}>{lang}</option>
                    ))}
                  </select>
                ))}
                <button type="button" onClick={handleAddWorkingLanguage}>
                  {t('Ajouer une langue de travail')}
                </button>
              </div>
            </div>
            <div>
              {workingLanguages.map((language, index) => (
                <input
                  key={index}
                  type="text"
                  placeholder={`${t('Langues de travail')} ${index + 1}`}
                  value={language}
                  onChange={(e) => handleLanguageChange(index, e.target.value)}
                />

              ))}
            </div>
            <div>
              <select value={selectedSpecialization} onChange={(e) => setSelectedSpecialization(e.target.value)}>
                <option value="">{t('Sélectionnez une spécialité')}</option>
                {SPECIALIZATIONS.map((specialization, index) => (
                  <option key={index} value={specialization}>{specialization}</option>
                ))}
              </select>
              <button type="button" onClick={handleAddSpecialization}>{t('Ajouter une spécialité')}</button>
              <ul>
                {specializations.map((spec, index) => (<li key={index}>{spec}</li>))}
              </ul>
            </div>
            <div>
              <select value={selectedDegree} onChange={(e) => setSelectedDegree(e.target.value)}>
                <option value="">{t('Sélectionnez un diplôme')}</option>
                {DEGREES.map((degree, index) => (
                  <option key={index} value={degree}>{degree}</option>
                ))}
              </select>
              <button type="button" onClick={handleAddDegree}>{t('Ajouter un diplôme')}</button>
              <ul>
                {degrees.map((deg, index) => (<li key={index}>{deg}</li>))}
              </ul>
            </div>

            <label >{t('Si vous êtes assermenté cochez cette case')} :</label>
            <input
              type="checkbox"
              checked={isSwornTranslator}
              onChange={(e) => setIsSwornTranslator(e.target.checked)}
            />
            <label >{t('Veuillez mettre votre document d\'assermentation')} :</label>

            <input
              type="file"
              name="swornTranslatorDoc"
              onChange={(e) => setSwornTranslatorDoc(e.target.files[0])}
            />
            <label>{t('Veuillez mettre votre document d\'identité')} :</label>
            {identificationDocs.map((doc, index) => (
              <input
                key={index}
                type="file"
                name={`identificationDoc[${index}]`}
                onChange={(e) => handleIdentificationDocChange(index, e.target.files[0])}
              />
            ))}
            <button className="addFile" type="button" onClick={handleAddIdentificationDoc}>
              {t('Ajouter un autre fichier')}
            </button>
            <div className='buttonSubmit'>
              <button className='submit' type="submit">{t('inscription')}</button></div>
          </form>
        </div>
      )}
    </div>
  );
};

export default SignupTranslator;
