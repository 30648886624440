//AdminDashboard.js
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import './css/Admin.css';
import { Link } from 'react-router-dom';
import ChatAdmin from './Chat/ChatAdmin';


const AdminDashboard = () => {
  const { admin, logoutAdmin } = useContext(AuthContext);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [proposedOrders, setProposedOrders] = useState([]);
  const [acceptedOrders, setAcceptedOrders] = useState([]);
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [translatedOrders, setTranslatedOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [selectedTranslatorPropose, setSelectedTranslatorPropose] = useState(null);
  const [availableTranslators, setAvailableTranslators] = useState([]);
  const [acceptedTranslators, setAcceptedTranslators] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [allAvailableTranslators, setAllAvailableTranslators] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [pendingComments, setPendingComments] = useState([]);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [proposedTranslators, setProposedTranslators] = useState([]);
  const [showChat, setShowChat] = useState(false);



  const apiUrl = `https://jetraduis-server.vercel.app/api`;
  const callApi = async (url, method, data = null) => {
    try {
      const response = await axios({
        method,
        url: `${apiUrl}/${url}`,
        data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error ${method} ${url}:`, error);
      throw error;
    }
  };

  const fetchAllAvailableTranslators = async (sourceLanguage, targetLanguage, orderId) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/admin/accepted/Translator?workingLanguages=${sourceLanguage}&nativeLanguage=${targetLanguage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setAllAvailableTranslators(response.data.translators);
    } catch (error) {
      console.error('Error fetching available translators:', error);
    }
  }

  const fetchProposedTranslators = async (order) => {
    try {
      const response = await axios.get(`https://jetraduis-server.vercel.app/api/admin/orders/proposedTranslator`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setProposedTranslators(response.data.proposedTranslators);
    } catch (error) {
      console.error('Error fetching proposed orders:', error);
    }
  };

  const fetchAllAvailableUsers = async () => {
    try {
      const response = await axios.get(`${process.env.HOST.URL}/api/admin/users/available`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setAvailableUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching available users:', error);
    }
  };

  const fetchOrders = async (orderStatus) => {
    try {
      const response = await callApi(`admin / orders / ${orderStatus}`, 'get');
      switch (orderStatus) {
        case 'pending':
          setPendingOrders(response.orders);
          break;
        case 'accepted':
          setAcceptedOrders(response.orders);
          break;
        case 'proposed':
          setProposedOrders(response.orders);
          break;
        case 'assigned':
          setAssignedOrders(response.orders);
          break;
        case 'translated':
          setTranslatedOrders(response.orders);
          break;
        case 'completed':
          setCompletedOrders(response.orders);
          break;
        case 'cancelled':
          setCancelledOrders(response.orders);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error fetching ${orderStatus} orders: `, error);
    }
  };

  const fetchOrderData = () => {
    const orderStatuses = [
      'pending',
      'accepted',
      'proposed',
      'assigned',
      'translated',
      'completed',
      'cancelled',
    ];
    orderStatuses.forEach((status) => fetchOrders(status));
  };

  useEffect(() => {
    fetchOrderData();
    fetchAllAvailableTranslators();
    fetchAllAvailableUsers();
    fetchPendingComments();
  }, []);

  const fetchAvailableTranslators = async () => {
    try {
      const response = await axios.get(`http://localhost:5000/api/admin/translators`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setAvailableTranslators(response.data.translators);
    } catch (error) {
      console.error('Error fetching available translators:', error);
    }
  };


  const fetchAcceptedTranslators = async (translator) => {

    try {
      const response = await axios.get(`https://jetraduis-server.vercel.app/api/admin/orders/accepted`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setAcceptedTranslators(response.data.orders);

    } catch (error) {
      console.error('Error fetching accepted orders:', error);
    }
  };

  const assignTranslator = async (orderId) => {
    try {
      await axios.post(
        `http://localhost:5000/api/orders/${orderId}/assign`,
        {
          translatorId: selectedTranslatorPropose,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchOrderData();
    } catch (error) {
      console.error('Error assigning2 translator:', error);
    }
  };

  const proposeTranslator = async (orderId) => {
    try {
      await axios.post(
        `http://localhost:5000/api/orders/${orderId}/propose`,
        {
          translatorIds: selectedTranslatorPropose,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchOrderData();
    } catch (error) {
      console.error('Error proposing translators:', error);
    }
  };

  const completeOrder = async (orderId) => {
    try {
      await axios.put(
        `http://localhost:5000/api/admin/orders/${orderId}/complete`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchOrderData();
    } catch (error) {
      console.error('Error complete order:', error);
    }
  };
  const cancelOrder = async (orderId) => {
    try {
      await axios.put(
        `http://localhost:5000/api/admin/orders/${orderId}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchOrderData();
    } catch (error) {
      console.error('Error cancelling order:', error);
    }
  };
  const unassignTranslator = async (orderId) => {
    try {
      await axios.put(
        `http://localhost:5000/api/admin/orders/${orderId}/unassign`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchOrderData();

    } catch (error) {
      console.error('Error unassigning translator:', error);
    }
  };
  const reactivateOrder = async (orderId) => {
    try {
      await axios.put(
        `http://localhost:5000/api/admin/orders/${orderId}/reactivate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchOrderData();
    } catch (error) {
      console.error('Error reactivating order:', error);
    }
  };
  const fetchPendingComments = async () => {
    try {
      const response = await axios.get(`https://jetraduis-server.vercel.app/api/admin/comments/pending`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      const filteredComments = response.data.orders.filter(order => {
        return order.comment.some(comment => comment.approved === false);
      });
      setPendingComments(filteredComments);
    } catch (error) {
      console.error('Error fetching pending comments:', error);
    }
  };
  const approveComment = async (commentId) => {
    try {
      const response = await axios.post(
        `http://localhost:5000/api/admin/comments/${commentId}/approve`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchPendingComments();
    } catch (error) {
      console.error('Error approving comment:', error);
    }
  };
  return (
    <div className="adminDash">
      {admin ? (
        <div>
          <div>
            <button
              className="list-group-item list-group-item-action"
              onClick={() => {
                // Inversez la valeur actuelle de showChat lors du clic sur le bouton "Chat"
                setShowChat(prevShowChat => !prevShowChat);
              }}
            >
              Chat
            </button>
            {showChat && <ChatAdmin />}
          </div>

          <h1 className="text-center">Admin Dashboard</h1>
          <p>Welcome, User ID: { }</p>
          <div className="row">
            <div className="col-md-3">
              <div className="list-group">

                <button
                  className="list-group-item list-group-item-action active"
                  onClick={() => {
                    fetchOrders('pending');
                    fetchAllAvailableTranslators();
                    fetchAllAvailableUsers();
                  }}
                >
                  Pending Orders
                </button>
                <button
                  className="list-group-item list-group-item-action active"
                  onClick={() => {
                    fetchOrders('proposed');
                    fetchAllAvailableTranslators();
                    fetchAvailableTranslators();
                    fetchAllAvailableUsers();
                  }}
                >
                  Proposed Orders
                </button>
                <button
                  className="list-group-item list-group-item-action active"
                  onClick={() => {
                    fetchOrders('accepted');
                    fetchAllAvailableTranslators();
                    fetchAllAvailableUsers();
                  }}
                >
                  Accepted Orders
                </button>
                <button
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    fetchOrders('assigned');
                    fetchAllAvailableTranslators();
                    fetchAllAvailableUsers();

                  }}
                >
                  Assigned Orders
                </button>
                <button
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    fetchOrders('translated');
                    fetchAllAvailableTranslators();
                    fetchAllAvailableUsers();
                  }}
                >
                  Translated Orders
                </button>
                <button
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    fetchOrders('completed');
                    fetchAllAvailableTranslators();
                    fetchAllAvailableUsers();
                  }}
                >
                  Completed Orders
                </button>
                <button
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    fetchOrders('cancelled');
                    fetchAllAvailableTranslators();
                    fetchAllAvailableUsers();
                  }}
                >
                  Cancelled Orders
                </button>
                <button
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    fetchPendingComments();
                    fetchAllAvailableTranslators();
                    fetchAllAvailableUsers();
                  }}
                >
                  Pending Comments
                </button>
                <Link to="/admin/translatorslist" className="list-group-item list-group-item-action">
                  <button>
                    Translators
                  </button>
                </Link>
                <button
                  className="list-group-item list-group-item-action"
                  onClick={fetchProposedTranslators}
                >
                  proposed Translators
                </button>
                <button
                  className="list-group-item list-group-item-action"
                  onClick={logoutAdmin}
                >
                  Logout
                </button>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Pending Orders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Source Language</th>
                        <th scope="col">Target Language</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Translator</th>
                        <th scope="col">Translation Type</th>
                        <th scope="col">Propose Translator</th>
                        <th scope="col">User</th>
                        <th scope="col">Cancel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingOrders.map((order) => (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.sourceLanguage}</td>
                          <td>{order.targetLanguage}</td>
                          <td>{order.totalAmount}€ </td>
                          <td>{order.amountTranslator}€ </td>
                          <td>{order.translationType}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setSelectedOrder(order);
                                setSelectedTranslatorPropose([]);
                              }}
                            >
                              Propose Order to Translator
                            </button>
                            {selectedOrder === order && (
                              <div>
                                <select
                                  multiple
                                  className="form-control"
                                  onChange={(e) => setSelectedTranslatorPropose([...e.target.selectedOptions].map(option => option.value))}
                                  value={selectedTranslatorPropose}
                                >
                                  <option value="" disabled>Select translators</option>
                                  {allAvailableTranslators.map((translator) => {
                                    const isLanguageMatch = translator.workingLanguages.includes(order.sourceLanguage) &&
                                      translator.nativeLanguage === order.targetLanguage;
                                    const isCertificationMatch = order.translationType === 'certified' ?
                                      translator.isSwornTranslator : true;
                                    return isLanguageMatch && isCertificationMatch && (
                                      <option key={translator._id} value={translator._id}>
                                        {translator.fullName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  className="btn btn-primary mt-2"
                                  onClick={() => {
                                    if (selectedTranslatorPropose.length > 0 && selectedOrder) {
                                      proposeTranslator(selectedOrder._id);
                                    }
                                  }}
                                >
                                  Propose
                                </button>
                              </div>
                            )}
                          </td>
                          <td> {availableUsers.map((user) => {
                            if (user._id === order.user._id) {
                              return (
                                <div key={user._id}>
                                  <p>User Name: {user.fullName}</p>
                                  <p>User Email: {user.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => cancelOrder(order._id)}
                            >
                              Cancel
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Proposed Orders */}
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Proposed Orders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Source Language</th>
                        <th scope="col">Target Language</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Translator</th>
                        <th scope="col">Translation Type</th>
                        <th scope="col">Proposed Translators</th>
                        <th scope="col">User</th>
                        <th scope="col">Cancel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {proposedOrders.map((order) => (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.sourceLanguage}</td>
                          <td>{order.targetLanguage}</td>
                          <td>{order.totalAmount}€ </td>
                          <td>{order.amountTranslator}€ </td>
                          <td>{order.translationType}</td>
                          <td>
                            {order.proposedTranslator && order.proposedTranslator.length > 0 ? (
                              <div>
                                <p>Number of Proposed Translators: {order.proposedTranslator.length}</p>
                                {order.proposedTranslator.map((proposed) => (
                                  allAvailableTranslators.map((translator) => {
                                    if (translator._id === proposed.translator) {
                                      return (
                                        <div key={translator._id}>
                                          <p>Translator ID: {translator._id}</p>
                                          <p>Translator Name: {translator.fullName}</p>
                                          <p>Translator Email: {translator.email}</p>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })
                                ))}
                              </div>
                            ) : (
                              <p>No proposed translators</p>
                            )}
                          </td>
                          <td> {availableUsers.map((user) => {
                            if (user._id === order.user._id) {
                              return (
                                <div key={user._id}>
                                  <p>User Name: {user.fullName}</p>
                                  <p>User Email: {user.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => cancelOrder(order._id)}
                            >
                              Cancel
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*accepted orders */}
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Accepted Orders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Source Language</th>
                        <th scope="col">Target Language</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Translator</th>
                        <th scope="col">Translation Type</th>
                        <th scope="col">Assign Translator</th>
                        <th scope="col">User</th>
                        <th scope="col">Cancel</th>
                      </tr>
                    </thead>
                    <tbody>
                      {acceptedOrders.map((order) => (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.sourceLanguage}</td>
                          <td>{order.targetLanguage}</td>
                          <td>{order.totalAmount}€ </td>
                          <td>{order.amountTranslator}€ </td>
                          <td>{order.translationType}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setSelectedOrder(order);
                                setSelectedTranslatorPropose([]);
                              }}
                            >
                              Assign Order to Translator
                            </button>
                            {selectedOrder === order && (
                              <div>
                                <select
                                  multiple
                                  className="form-control"
                                  onChange={(e) => setSelectedTranslatorPropose([...e.target.selectedOptions].map(option => option.value))}
                                  value={selectedTranslatorPropose}
                                >
                                  <option value="" disabled>Select translators</option>
                                  {selectedOrder && selectedOrder.proposedTranslator.map((proposed) => {
                                    const translator = allAvailableTranslators.find(t => t._id === proposed.translator);
                                    return translator ? (
                                      <option key={translator._id} value={translator._id}>
                                        {translator.fullName}
                                      </option>
                                    ) : null;
                                  })}
                                </select>
                                <button
                                  className="btn btn-primary mt-2"
                                  onClick={() => {
                                    if (selectedTranslatorPropose.length > 0 && selectedOrder) {
                                      assignTranslator(selectedOrder._id);
                                    }
                                  }}
                                >
                                  Assign
                                </button>
                              </div>
                            )}
                          </td>
                          <td> {availableUsers.map((user) => {
                            if (user._id === order.user._id) {
                              return (
                                <div key={user._id}>
                                  <p>User Name: {user.fullName}</p>
                                  <p>User Email: {user.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => cancelOrder(order._id)}
                            >
                              Cancel
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* assigned orders */}
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Assigned Orders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Source Language</th>
                        <th scope="col">Target Language</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Translator</th>
                        <th scope="col">Translation Type</th>
                        <th scope="col">Translator</th>
                        <th scope="col">User</th>
                        <th scope="col">Cancel</th>
                        <th scope="col">unassign</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignedOrders.map((order) => (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.sourceLanguage}</td>
                          <td>{order.targetLanguage}</td>
                          <td>{order.totalAmount}€ </td>
                          <td>{order.amountTranslator}€ </td>
                          <td>{order.translationType}</td>
                          <td> {allAvailableTranslators.map((translator) => {
                            if (translator._id === order.translator) {
                              return (
                                <div key={translator._id}>
                                  <p> Name: {translator.fullName}</p>
                                  <p> Email: {translator.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                          <td> {availableUsers.map((user) => {
                            if (user._id === order.user._id) {
                              return (
                                <div key={user._id}>
                                  <p> Name: {user.fullName}</p>
                                  <p> Email: {user.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => cancelOrder(order._id)}
                            >
                              Cancel
                            </button>
                          </td>
                          <td>
                            {order.translator ? (
                              <button
                                className="btn btn-danger"
                                onClick={() => unassignTranslator(order._id)}
                              >
                                Unassign Translator
                              </button>
                            ) : (
                              <span></span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* translated orders */}
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Translated Orders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Source Language</th>
                        <th scope="col">Target Language</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Translator</th>
                        <th scope="col">Translation Type</th>
                        <th scope="col">Translator</th>
                        <th scope="col">Client </th>
                        <th scope="col">Cancel</th>
                        <th scope="col">complete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {translatedOrders.map((order) => (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.sourceLanguage}</td>
                          <td>{order.targetLanguage}</td>
                          <td>{order.totalAmount}€ </td>
                          <td>{order.amountTranslator}€ </td>
                          <td>{order.translationType}</td>
                          <td>
                            {order.translator ? (
                              (() => {
                                const assignedTranslator = allAvailableTranslators.find(translator => translator._id === order.translator);
                                if (assignedTranslator) {
                                  return assignedTranslator.fullName;
                                } else {
                                  return 'No Translator Assigned';
                                }
                              })()
                            ) : (
                              <span>No Translator Assigned</span>
                            )}
                          </td>
                          <td> {availableUsers.map((user) => {
                            if (user._id === order.user._id) {
                              return (
                                <div key={user._id}>
                                  <p>User Name: {user.fullName}</p>
                                  <p>User Email: {user.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={() => cancelOrder(order._id)}
                            >
                              Cancel
                            </button>
                          </td>
                          <td>
                            {order.translator ? (
                              <div>

                                <button
                                  className="btn btn-success ml-2"
                                  onClick={() => completeOrder(order._id)}
                                >
                                  Mark as Completed
                                </button>
                              </div>
                            ) : (
                              <span>No Translator Assigned</span>
                            )}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* completed orders */}
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Completed Orders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Source Language</th>
                        <th scope="col">Target Language</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Translator</th>
                        <th scope="col">Translation Type</th>
                        <th scope="col">Translator</th>
                        <th scope="col">User</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completedOrders.map((order) => (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.sourceLanguage}</td>
                          <td>{order.targetLanguage}</td>
                          <td>{order.totalAmount}€ </td>
                          <td>{order.amountTranslator}€ </td>
                          <td>{order.translationType}</td>
                          <td> {allAvailableTranslators.map((translator) => {
                            if (translator._id === order.translator) {
                              return (
                                <div key={translator._id}>
                                  <p>Translator Name: {translator.fullName}</p>
                                  <p>Translator Email: {translator.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                          <td> {availableUsers.map((user) => {
                            if (user._id === order.user._id) {
                              return (
                                <div key={user._id}>
                                  <p>User Name: {user.fullName}</p>
                                  <p>User Email: {user.email}</p>
                                </div>
                              );
                            }
                            return null;
                          })} </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* cancelled orders */}
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Cancelled Orders</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Order Number</th>
                        <th scope="col">Source Language</th>
                        <th scope="col">Target Language</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Amount Translator</th>
                        <th scope="col">Translation Type</th>
                        <th scope="col">Order id</th>
                        <th scope="col">Actions</th>
                        <th scope="col">User</th>
                        <th scope="col">CancelledBy</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cancelledOrders.map((order) => (
                        <tr key={order._id}>
                          <td>{order.orderNumber}</td>
                          <td>{order.sourceLanguage}</td>
                          <td>{order.targetLanguage}</td>
                          <td>{order.totalAmount}€ </td>
                          <td>{order.amountTranslator}€ </td>
                          <td>{order.translationType}</td>
                          <td>{order._id}</td>
                          <td>
                            <button
                              className="btn btn-success"
                              onClick={() => reactivateOrder(order._id)}
                            >
                              Reactivate
                            </button>
                          </td>
                          <td>{order.user._id}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
              </div>
              <div className="card text-center">
                <div className="card-body">
                  <h5 className="card-title">Pending Comments</h5>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Comment ID</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">User</th>
                        <th scope="col">Content</th>
                        <th scope="col">Comment files</th>
                        <th scope="col">Approve</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingComments.map((order) => (
                        order.comment
                          .filter((comment) => !comment.approved)
                          .map((comment) => (
                            <tr key={comment._id}>
                              <td>{comment._id}</td>
                              <td>{order.orderNumber}</td>
                              <td>{comment.fullName}</td>
                              <td>{comment.text}</td>
                              <td>
                                {order.commentFile && order.commentFile.length > 0 ? (
                                  order.commentFile.map((fileName, index) => (
                                    <div key={index}>
                                      <p>{fileName}</p>
                                    </div>
                                  ))
                                ) : (
                                  <p>No files</p>
                                )}
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setSelectedCommentId(comment._id);
                                    approveComment(comment._id);
                                  }}
                                >
                                  Approve
                                </button>
                              </td>
                            </tr>
                          ))
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      ) : (
        <p>Please log in to access the dashboard.</p>
      )}
    </div>
  );
}
export default AdminDashboard;
