import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../contexts/AuthContext';
import './components.css';

const Button = () => {
  const { logoutUser } = useContext(AuthContext);
  const handleLogout = () => {
    logoutUser();
  };

  return (
    <button className='logOut' onClick={handleLogout}>Déconnexion</button>
  );
};

const ButtonSearch = ({ text, type = 'button', onClick }) => (
  <button type={type} onClick={onClick}>
    {text}
  </button>
);

ButtonSearch.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export { Button, ButtonSearch };
