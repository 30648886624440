// BurgerMenu.js
import React from 'react';
import './components.css';

const BurgerMenu = ({ isOpen, toggleMenu }) => {
    const handleToggle = () => {
        toggleMenu();
    };
    return (
        <div className={`burger-menu ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>
    );
};

export default BurgerMenu;
