import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';

import axios from 'axios';

function AcceptOrderComponent() {
    const location = useLocation();
    const languageContext = useContext(LanguageContext);
    const { translations } = languageContext || {};
    const t = (key) => translations[key] || key;
    const [message, setMessage] = useState('Traitement de l’acceptation de la commande...');
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            acceptOrder(token);
        }
    }, [location]);

    const acceptOrder = async (token) => {
        try {
            await axios.post(`https://jetraduis-server.vercel.app/api/orders/accept-order`, { token });
            setMessage('La commande a été acceptée avec succès.');
        } catch (error) {
            console.error('Error accepting order:', error);
            setMessage(error.response?.data?.message || 'Erreur lors de l’acceptation de la commande.');
        }
    };
    return (
        <div>{t(message)}</div>
    );
}

export default AcceptOrderComponent;
