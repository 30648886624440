import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/Admin.css';

const TranslatorsList = () => {
  const [availableTranslators, setAvailableTranslators] = useState([]);

  const fetchAvailableTranslators = async () => {
    try {
      const response = await axios.get(`https://jetraduis-server.vercel.app/api/admin/translators`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      setAvailableTranslators(response.data.translators);
    } catch (error) {
      console.error('Error fetching available translators:', error);
    }
  };

  const approveTranslator = async (userId) => {
    try {
      await axios.put(
        `https://jetraduis-server.vercel.app/api/admin/approveTranslator/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchAvailableTranslators();
    } catch (error) {
      console.error('Error approving translator:', error);
    }
  };

  const approveTranslatorAsModerator = async (userId) => {
    try {
      await axios.put(
        `https://jetraduis-server.vercel.app/api/admin/approveTranslatorAsModerator/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchAvailableTranslators();
    } catch (error) {
      console.error('Error approving translator:', error);
    }
  };
  useEffect(() => {
    fetchAvailableTranslators();
  }, []);

  const DesapproveTranslator = async (userId) => {
    try {
      await axios.put(
        `https://jetraduis-server.vercel.app/api/admin/DesapproveTranslator/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
          },
        }
      );
      fetchAvailableTranslators();
    } catch (error) {
      console.error('Error approving translator:', error);
    }
  };
  useEffect(() => {
    fetchAvailableTranslators();
  }, []);
  return (
    <div className="adminDash">
      <h1 className="text-center">Admin Dashboard</h1>
      <p>Translator List</p>
      <div className="card text-center">
        <div className="card-body">
          <h5 className="card-title">Approved Translators</h5>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Translator Name</th>
                <th scope="col">Working Languages</th>
                <th scope="col">Native Language</th>
                <th scope="col">Status</th>
                <th scope="col">Approve or Disapprove</th>
                <th scope="col">Approve as Moderator</th>

              </tr>
            </thead>
            <tbody>
              {availableTranslators.map((translator) => (
                <tr key={translator._id}>
                  <td>{translator.fullName}</td>
                  <td>{translator.workingLanguages}</td>
                  <td>{translator.nativeLanguage}</td>
                  <td>{translator.status}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => approveTranslator(translator._id)}
                    >
                      Approve Translator
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => DesapproveTranslator(translator._id)}
                    >
                      Desapprove Translator
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => approveTranslatorAsModerator(translator._id)}
                    >
                      Approve as Moderator
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TranslatorsList;
