//About.js

import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import './css/Login.css';

const About = () => {
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;

  return (
    <section aria-labelledby="aboutHeading">
      <h2 id="aboutHeading">{t('À propos de nous')}</h2>
      <p>{t('Bienvenue sur notre site de services de traduction. Nous nous engageons à fournir des services de traduction de haute qualité à nos clients')}.</p>
      <p>{t('Notre équipe de traducteurs expérimentés garantit des traductions précises et fiables pour divers documents et langues')}.</p>
      <p>
        {t('Chez JeTraduis, nous comprenons l\'importance de la communication dans un monde globalisé. C\'est pourquoi nous offrons une large gamme de services de traduction pour répondre à tous vos besoins linguistiques. Que vous ayez besoin de traductions certifiées pour des documents officiels, de localisation pour adapter vos contenus aux marchés locaux, ou d\'interprétation pour vos conférences et réunions, notre équipe d\'experts est là pour vous aider')}

        {t('Nous sommes fiers de fournir des services de traduction de haute qualité dans une multitude de langues, assurant précision, rapidité et confidentialité. Nos traducteurs professionnels sont spécialisés dans divers domaines, garantissant ainsi que votre projet est entre les mains les plus compétentes')}.

        {t('Découvrez la liste complète de nos services en cliquant ci-dessous et voyez comment nous pouvons vous aider à franchir les barrières linguistiques')}.
      </p>
      <p>{t('Contactez-nous dès aujourd\'hui pour commencer votre projet de traduction')} !</p>
    </section>
  );
};

export default About;
