//Services.js
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import '../pages/css/Services.css';
import LoginUserModal from '../components/LoginUserModal';
import { LanguageContext } from '../contexts/LanguageContext';
import { getNames } from 'country-list';
import { FaFacebook, FaXTwitter, FaInstagram } from 'react-icons/fa6';


const ServicesCertified = () => {
  const [translationType, setTranslationType] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('');
  const [numPages, setNumPages] = useState(1);
  const [totalAmount, setTotalAmount] = useState(30);
  const [sendFile, setSendFile] = useState([]);
  const [sourceLanguage, setSourceLanguage] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('');
  const [sourceLanguages, setSourceLanguages] = useState([]);
  const [targetLanguages, setTargetLanguages] = useState([]);
  const [amountTranslator, setamountTranslator] = useState(0);
  const [commentOnOrder, setCommentOnOrder] = useState(' ');
  const [address, setAddress] = useState(' ');
  const [showSignupModal, setShowSignupModal] = useState(false);
  const languageContext = useContext(LanguageContext);
  const { translations } = languageContext || {};
  const t = (key) => translations[key] || key;
  const [legalization, setLegalization] = useState(false);
  const [legalizationJurisdictions, setLegalizationJurisdictions] = useState([]);

  const [legalizationCountry, setLegalizationCountry] = useState('');
  const countryOptions = getNames(); // Obtenez la liste des noms de pays
  const [validationErrors, setValidationErrors] = useState({});


  const handleSourceLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setSourceLanguage(selectedLanguage);

    // Clear the error message for sourceLanguage
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      sourceLanguage: '',
    }));
  };

  const handleTargetLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setTargetLanguage(selectedLanguage);

    // Clear the error message for sourceLanguage
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      targetLanguage: '',
    }));
  };



  // États pour la gestion des erreurs
  const [errors, setErrors] = useState({
    translationType: '',
    sourceLanguage: '',
    targetLanguage: '',
    numPages: '',
    deliveryOption: '',
    commentOnOrder: '',
    address: '',
  });
  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      translationType: '',
      sourceLanguage: '',
      targetLanguage: '',
      numPages: '',
      deliveryOption: '',
      commentOnOrder: '',
      address: '',
    };

    if (!translationType) {
      newErrors.translationType = 'Veuillez sélectionner un type de traduction.';
      isValid = false;
    }
    if (!sourceLanguage) {
      newErrors.sourceLanguage = 'Veuillez sélectionner la langue du document.';
      isValid = false;
    }
    if (!targetLanguage) {
      newErrors.targetLanguage = 'Veuillez sélectionner la langue à laquelle vous traduisez.';
      isValid = false;
    }
    if (!deliveryOption) {
      newErrors.deliveryOption = 'Veuillez sélectionner une option de livraison.';
      isValid = false;
    }
    if (!legalizationCountry) {
      newErrors.legalizationCountry = 'Veuillez sélectionner un pays pour la certification.';
      isValid = false;
    }
    // ... autres validations ...

    if (!isValid) {
      setValidationErrors(newErrors);
    } else {
      setValidationErrors({});
    }

    return isValid;
  };

  const handleAddFiles = () => {
    setSendFile([...sendFile, '']);
  };
  const handleAddFilesChange = (index, value) => {
    const updatedFiles = [...sendFile];
    updatedFiles[index] = value;
    setSendFile(updatedFiles);
  };

  useEffect(() => {
    axios.get(`https://jetraduis-server.vercel.app/api/translators/source-languages`)
      .then(response => {
        setSourceLanguages(response.data.sourceLanguages);

      })
      .catch(error => {
        console.error('Erreur lors de la récupération des langues de source :', error);
      });
    axios.get(`https://jetraduis-server.vercel.app/api/translators/target-languages`)
      .then(response => {
        setTargetLanguages(response.data.targetLanguages);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des langues de destination :', error);
      });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type');

    if (type === 'certified' || type === 'general') {
      setTranslationType(type);
    }
  }, [location]);



  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (!user) {
      setShowSignupModal(true);
    } else {
      setShowSignupModal(false);
    }
  }, [user]);


  const handleNumPagesChange = (e) => {
    const action = e.target.name;
    let newNumPages = numPages;
    if (action === 'increment') {
      newNumPages += 1;
    } else if (action === 'decrement' && newNumPages > 0) {
      newNumPages -= 1;
    }
    setNumPages(newNumPages);
    calculateTotalAmount(newNumPages, deliveryOption);
  };

  const handleDeliveryOptionChange = (newDeliveryOption) => {
    setDeliveryOption(newDeliveryOption);
    calculateTotalAmount(numPages, newDeliveryOption);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      deliveryOption: '',
    }));
  };





  const handleCountryChange = (e) => {
    setLegalizationCountry(e.target.value);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      legalizationCountry: '',
    }));

  };


  const handleLegalizationJurisdictionsChange = (option) => {
    setLegalizationJurisdictions((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
    calculateTotalAmount(numPages, deliveryOption, legalizationJurisdictions);
  };


  const calculateTotalAmount = (pages, option, legalizationOptions) => {
    let amount = pages * 30;

    if (option === 'postal') {
      amount += 15;
    } else if (option === 'priority') {
      amount += 30;
    } else if (option === 'dhl') {
      amount += 100;
    }
    if (legalizationOptions) {
      if (legalizationOptions.includes('ministryOfForeignAffairs')) {
        amount += 50; // Coût supplémentaire pour le ministère des affaires étrangères
      }
      if (legalizationOptions.includes('consulateAndEmbassy')) {
        amount += 40; // Coût supplémentaire pour le consulat et l'ambassade
      }
      if (legalizationOptions.includes('cityHall')) {
        amount += 20; // Coût supplémentaire pour la mairie
      }
      if (legalizationOptions.includes('chamberOfCommerce')) {
        amount += 30; // Coût supplémentaire pour la chambre de commerce
      }
    }



    const amountTranslator = 0.8 * amount;
    setTotalAmount(amount);
    setamountTranslator(amountTranslator);
  };
  useEffect(() => {
    calculateTotalAmount(numPages, deliveryOption, legalizationJurisdictions);
  }, [numPages, deliveryOption, legalizationJurisdictions]);

  const handleAddOrder = async (e) => {
    e.preventDefault();
    // Validate the form before submitting
    if (!validateForm()) {
      // Form is not valid, do not proceed with submission
      return;
    }

    const orderNumber = uuidv4();
    const amountTranslator = 0.8 * totalAmount;


    const formData = new FormData();
    formData.append('orderNumber', orderNumber);
    formData.append('user', user ? user._id : null);
    formData.append('translationType', translationType);
    formData.append('sourceLanguage', sourceLanguage);
    formData.append('targetLanguage', targetLanguage);
    formData.append('deliveryOption', deliveryOption);
    formData.append('numPages', numPages);
    formData.append('commentOnOrder', commentOnOrder || 'no comment');
    formData.append('address', address || 'no address');
    formData.append('legalizationJurisdictions[]', legalizationJurisdictions || ['']);
    formData.append('legalizationCountry', legalizationCountry || 'no country');
    sendFile.forEach((file, index) => {
      formData.append('sendFile', file);
    });
    formData.append('totalAmount', totalAmount);
    formData.append('amountTranslator', amountTranslator);
    try {
      const response = await axios.post(`https://jetraduis-server.vercel.app/api/orders/cart`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { order } = response.data;
      localStorage.setItem('orderId', order._id);
      navigate(`/ cart ? orderId = ${order._id}`);
    } catch (error) {
      console.error('Error confirming order:', error);

    }
  };


  return (

    <div className='services-container'>
      <h2>{t('Services')}</h2>
      <form onSubmit={handleAddOrder}>
        <div>
          <h3>{t('Type de traduction')} :</h3>
          <select className='decorated' value={translationType} onChange={(e) => setTranslationType(e.target.value)}>
            <option value="certified">{t('Traduction certifiée')}</option>
          </select>
        </div>
        <div>
          <h3>{t('Sélectionnez la langue de votre document')} :</h3>
          <select value={sourceLanguage} onChange={handleSourceLanguageChange}>
            <option value="">{t('Sélectionner')}...</option>
            {sourceLanguages.map(language => (
              <option key={language} value={language}>{t(language)}</option>
            ))}
          </select>
          {validationErrors.sourceLanguage && (
            <p className="error-message">{validationErrors.sourceLanguage}</p>
          )}
        </div>

        <div>
          <h3>{t('Sélectionnez la langue de votre document')} :</h3>
          <select value={targetLanguage} onChange={handleTargetLanguageChange}>
            <option value="">{t('Sélectionner')}...</option>
            {targetLanguages.map(language => (
              <option key={language} value={language}>{t(language)}</option>
            ))}
          </select>
          {validationErrors.targetLanguage && (
            <p className="error-message">{validationErrors.targetLanguage}</p>
          )}
        </div>
        <div>
          <label>
            Pays :
            <select value={legalizationCountry} onChange={handleCountryChange}>
              <option value="">Select Country</option>
              {countryOptions.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
            {validationErrors.legalizationCountry && (
              <p className="error-message">{validationErrors.legalizationCountry}</p>
            )}
          </label>
        </div>
        <div className='number-input-container'>
          <h3>{t('Nombre de pages')} :</h3>
          <div className="button-container">
            <input
              className='number-input'
              type="text"
              name="numPages"
              value={numPages}
              readOnly
            />
            <button type="button" className='number-input-button' name="decrement" onClick={handleNumPagesChange}>-</button>
            <button type="button" className='number-input-button' name="increment" onClick={handleNumPagesChange}>+</button>
          </div>
          <p>{t('Coût par page ')}: 30 € </p>
          <p>{t('Coût total des pages ')}: {numPages * 30} € </p>
        </div>
        <div>
          <h3>Légalisation :</h3>
          <label>
            <input
              type="checkbox"
              checked={legalization}
              onChange={() => setLegalization(!legalization)}
            />
            Légalisation requise
          </label>
        </div>
        {legalization && (
          <div>
            <div>
              <h3>Juridiction de légalisation :</h3>
              <label>
                <input
                  type="checkbox"
                  checked={legalizationJurisdictions.ministryOfForeignAffairs}
                  onChange={() => handleLegalizationJurisdictionsChange('ministryOfForeignAffairs')}
                />
                Ministère des affaires étrangères
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={legalizationJurisdictions.consulateAndEmbassy}
                  onChange={() => handleLegalizationJurisdictionsChange('consulateAndEmbassy')}
                />
                Consulat et Ambassade
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={legalizationJurisdictions.cityHall}
                  onChange={() => handleLegalizationJurisdictionsChange('cityHall')}
                />
                Mairie
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={legalizationJurisdictions.chamberOfCommerce}
                  onChange={() => handleLegalizationJurisdictionsChange('chamberOfCommerce')}
                />
                Chambre de commerce
              </label>
            </div>

          </div>
        )}
        <div>
          <h3>{t('Option de livraison')} :</h3>
          <label>
            <input
              type="radio"
              value="email"
              checked={deliveryOption === 'email'}
              onChange={() => handleDeliveryOptionChange('email')}
            />
            {t('Email')} {t('(Gratuit)')}
          </label>
          <label>
            <input
              type="radio"
              value="postal"
              checked={deliveryOption === 'postal'}
              onChange={() => handleDeliveryOptionChange('postal')}
            />
            {t('Livraison postale ')} (15 €)
          </label>
          <label>
            <input
              type="radio"
              value="priority"
              checked={deliveryOption === 'priority'}
              onChange={() => handleDeliveryOptionChange('priority')}
            />
            {t('Livraison postale prioritaire ')} (30 €)
          </label>
          <label>
            <input
              type="radio"
              value="dhl"
              checked={deliveryOption === 'dhl'}
              onChange={() => handleDeliveryOptionChange('dhl')}
            />
            {t('Livraison')} DHL (100 €)
          </label>
          {validationErrors.deliveryOption && (
            <p className="error-message">{validationErrors.deliveryOption}</p>
          )}
        </div>
        <div>
          <h3>{t('Ajouter des fichiers')} :</h3>
          <button onClick={handleAddFiles}> {t('Ajouter un fichier')} </button>
          {sendFile.map((sendFile, index) => (
            <input
              key={index}
              type="file"
              onChange={(e) => handleAddFilesChange(index, e.target.files[0])}
            />
          ))}
        </div>
        <div>
          <h3>Commentaire :</h3>
          <textarea
            rows="4"
            cols="50"
            placeholder="Saisissez votre commentaire"
            value={commentOnOrder}
            onChange={(e) => setCommentOnOrder(e.target.value)}
          ></textarea>
        </div>
        <div>
          <h3>Adresse :</h3>
          <textarea
            rows="4"
            cols="50"
            placeholder="Saisissez votre adresse"
            value={address}
            onChange={(e) => setAddress(e.target.value)}

          >
            {errors.translationType && <p className="error-message">{errors.translationType}</p>}

          </textarea>
        </div>
        <p className="cost-display">{t('Montant total')} : {totalAmount} €</p>
        <button type="submit" disabled={!user}>
          {t('Ajouter au panier')}
        </button>

      </form>
      {showSignupModal && <LoginUserModal />}
      <div className='why-choose-us'>
        <h3>{t('Pourquoi nous choisir')} :</h3>
        <p>
          {t('Notre équipe de traducteurs expérimentés assure des traductions précises et fiables dans divers domaines. Que ce soit pour des documents officiels, des textes techniques ou des contenus créatifs, nous sommes là pour vous aider')} .
        </p>
      </div>
      <div className='translator-collaboration'>
        <h3>{t('Collaboration avec des traducteurs')} :</h3>
        <p>
          {t('Êtes-vous un traducteur professionnel ? Rejoignez notre équipe et collaborez avec nous pour offrir des services de traduction exceptionnels à nos clients')}. <Link to="/signup/translator">{t('Inscrivez-vous ici')} </Link>.
        </p>
      </div>
      <div>
        <h3>{t('Restez connecté ')} :</h3>
        <p>
          {t('Suivez-nous sur les réseaux sociaux pour les dernières mises à jour, promotions et astuces linguistiques. Rejoignez notre communauté en ligne et explorez le monde des langues avec nous')}.
        </p>
        <div className='social-links'>
          <a href="#" target="_blank" rel="noopener noreferrer" aria-label={t('Facebook')}>
            <FaFacebook />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" aria-label={t('Twitter')}>
            <FaXTwitter />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer" aria-label={t('Instagram')}>
            <FaInstagram />
          </a>
        </div>
      </div>
      <div>
        <h3>{t('Service client dédié')} :</h3>
        <p>
          {t('Notre équipe de service client est disponible 24h/24 et 7j/7 pour répondre à toutes vos questions. N\'hésitez pas à nous contacter si vous avez besoin d\'aide ou d\'assistance')}.
        </p>
        <p>{t('Numéro de téléphone du service client')} : XXX-XXX-XXXX</p>
        <p>{t('Adresse e-mail du service client')} : support@votreentreprise.com</p>
      </div>
      <div>
        <h3>{t('Inscrivez-vous pour des offres exclusives')} :</h3>
        <p>
          {t('Abonnez-vous à notre newsletter pour recevoir des offres spéciales, des réductions et des conseils linguistiques directement dans votre boîte de réception. Ne manquez aucune opportunité')} !
        </p>
        <form className='form-abonner'>
          <input className='place-abonner' type="email" placeholder={t('Votre adresse e-mail')} />
          <button className='button-abonner' type="submit">{t("S'abonner")}</button>
        </form>
      </div>
    </div>
  );
};

export default ServicesCertified;

